import { cx } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { DatePickerProvider, DPDatesConfig } from "@rehookify/datepicker";
import endOfDay from "date-fns/endOfDay";
import isToday from "date-fns/isToday";
import { forwardRef, useImperativeHandle, useState } from "react";
import { DayPicker } from "./components/DayPicker/DayPicker";
import { MonthPicker } from "./components/MonthPicker/MonthPicker";
import { YearPicker } from "./components/YearPicker/YearPicker";
import { DatePickerView } from "./types";

type DatePickerDateTransformation = "none" | "endOfDay";

export interface DatePickerProps {
  initialDate?: Date | Date[];
  minDate?: Date;
  maxDate?: Date;
  isDateRemovable?: boolean;
  removeDateLabel?: string;
  dateTransformation?: DatePickerDateTransformation;
  className?: string;
  showTimePicker?: boolean;
  mode?: DPDatesConfig["mode"];
  showCancelButton?: boolean;
  maxRangeInMonths?: number;
  onSubmit?: (date: Date | null, date2?: Date | null) => void;
  onDaySelect?: (date: Date) => void;
  onClose?: () => void;
}

export interface DatePickerHandle {
  reset: (date?: Date) => void;
}

export const DatePicker = forwardRef<DatePickerHandle, DatePickerProps>(
  (
    {
      initialDate,
      minDate,
      maxDate,
      isDateRemovable,
      removeDateLabel,
      dateTransformation = "none",
      className,
      showTimePicker,
      mode,
      showCancelButton,
      maxRangeInMonths,
      onSubmit,
      onDaySelect,
      onClose,
    },
    ref
  ) => {
    const [currentView, setCurrentView] = useState<DatePickerView>("dayPicker");

    const [selectedDates, setSelectedDates] = useState<Date[]>(() =>
      initialDate && Array.isArray(initialDate)
        ? initialDate
        : initialDate
        ? [initialDate]
        : []
    );

    const { dateLocale } = useLanguage();

    useImperativeHandle(ref, () => ({
      reset: (date?: Date) => setSelectedDates(date ? [date] : []),
    }));

    const transformDate = (date: Date) => {
      if (dateTransformation === "endOfDay") {
        return endOfDay(date);
      }

      return date;
    };
    return (
      <DatePickerProvider
        config={{
          selectedDates,
          onDatesChange: setSelectedDates,
          calendar: {
            startDay: 1,
          },
          years: {
            numberOfYears: 240,
            mode: "fluid",
          },
          locale: {
            day: "numeric",
            locale: dateLocale?.code || "en",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          },
          dates: {
            minDate,
            maxDate,
            mode,
          },
          time: showTimePicker
            ? {
                minTime: isToday(selectedDates[0])
                  ? {
                      h: new Date().getHours(),
                      m: new Date().getMinutes(),
                    }
                  : undefined,
                interval: 5,
              }
            : undefined,
        }}
      >
        <div className={cx("bg-white", className)}>
          {currentView === "dayPicker" && (
            <DayPicker
              initialDate={initialDate}
              isDateRemovable={isDateRemovable}
              removeDateLabel={removeDateLabel}
              onViewChange={setCurrentView}
              minDate={minDate}
              maxDate={maxDate}
              showTimePicker={showTimePicker}
              maxRangeInMonths={maxRangeInMonths}
              onSubmit={
                onSubmit
                  ? (date, date2) =>
                      onSubmit(
                        date ? transformDate(date) : null,
                        date2 ? transformDate(date2) : null
                      )
                  : undefined
              }
              onDatesChange={setSelectedDates}
              onDaySelect={
                onDaySelect
                  ? (date) => onDaySelect(transformDate(date))
                  : undefined
              }
              onClose={onClose}
              mode={mode}
              showCancelButton={showCancelButton}
            />
          )}
          {currentView === "monthPicker" && (
            <MonthPicker
              hasNavigation={!!onSubmit}
              onViewChange={setCurrentView}
            />
          )}
          {currentView === "yearPicker" && (
            <YearPicker
              hasNavigation={!!onSubmit}
              onViewChange={setCurrentView}
            />
          )}
        </div>
      </DatePickerProvider>
    );
  }
);
