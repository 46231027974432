import { TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { Avatar } from "@jugl-web/ui-components";
import {
  cx,
  isoToLocalDate,
  useAppVariant,
  useTranslations,
} from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import { sanitize } from "dompurify";
import React, { useMemo } from "react";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as ChartIcon } from "./assets/chart.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

export const TaskDetailsSection: React.FC<{ taskData: GuestTaskDataModel }> = ({
  taskData: {
    task,
    entity: { img: entityImage, title: entityName },
    customStatuses,
  },
}) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();
  const { isMobile } = useAppVariant();

  const isTaskCompleted = task?.status === TaskDefaultStatusId.completed;

  const taskStatus = useMemo(
    () =>
      customStatuses?.find((item) => item.id === task.status)?.text ||
      t({
        id: "guest-task-page.custom-status",
        defaultMessage: "Custom status",
      }),
    [task, customStatuses, t]
  );

  return (
    <div
      className={cx("flex flex-col gap-7 px-4 pt-8", {
        "px-0": isMobile,
      })}
    >
      <div className="flex items-center gap-2">
        <Avatar imageUrl={entityImage} size="sm" username={entityName} />
        <span className="font-secondary truncate text-xs text-[#828282]">
          {entityName}
        </span>
      </div>

      <div className="flex flex-col gap-2">
        <span
          className={cx(
            "text-dark font-secondary break-words font-medium leading-[25px] tracking-[0.18px]",
            isMobile ? "text-lg " : "text-[22px]"
          )}
        >
          {task.name}
        </span>
        {task.desc && (
          <span
            className="text-dark-600 break-words text-sm leading-3 tracking-[0.14px]"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitize(task.desc, {
                ADD_ATTR: ["target"],
              }),
            }}
          />
        )}
      </div>

      <div className={cx("flex flex-wrap gap-2.5", isMobile && "flex-col")}>
        <div
          className={cx(
            "flex h-8 w-max items-center gap-1.5 rounded-2xl bg-[#FFECF2] px-4",
            isTaskCompleted
              ? "text-secondary bg-[#ECFFF6]"
              : "text-gradients-g2 bg-[#FFECF2]"
          )}
        >
          <ChartIcon />
          <span className="text-dark text-xs">
            {t(
              {
                id: "guest-task-page.task-status",
                defaultMessage: "Status: {status}",
              },
              {
                status: taskStatus,
              }
            )}
          </span>
          {isTaskCompleted && <CheckIcon />}
        </div>
        {task.due_at && (
          <div
            className={cx(
              "flex h-8 w-max items-center gap-1.5 rounded-2xl px-4",
              isBefore(
                task.completed_on
                  ? isoToLocalDate(task.completed_on)
                  : new Date(),
                isoToLocalDate(task.due_at)
              )
                ? "text-secondary bg-[#ECFFF6]"
                : "text-gradients-g2 bg-[#FFECF2]"
            )}
          >
            <CalendarIcon />
            <span className="text-dark text-xs">
              {t(
                {
                  id: "guest-task-page.task-due-date",
                  defaultMessage: "Due to: {date}",
                },
                {
                  date: format(isoToLocalDate(task.due_at), "MMMM d, hh:mm a", {
                    locale: dateLocale,
                  }),
                }
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
