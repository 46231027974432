import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import {
  Button,
  Fab,
  PageLoaderFull,
  InteractiveContainer,
  LoadingSpinner,
  OTPVerificationForm,
  PhoneInput,
  TextField,
} from "@jugl-web/ui-components";
import { MethodTab } from "./components/MethodTab";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as HelpIcon } from "./assets/help.svg";
import { AuthContentProps } from "../../types/AuthContentProps";
import { PageWrapper } from "./components/PageWrapper/PageWrapper";
import { useIntercom } from "../../../../../../components/IntercomProvider";

export const WebAuthContent: FC<AuthContentProps> = ({
  loginMethod,
  email,
  mobile,
  isVerifyOtpLoading,
  isRequestOtpLoading,
  isLoginFormValid,
  loginId,
  loginValue,
  setLoginId,
  handleRequestOtp,
  setOtp,
  handleVerifyOtp,
  setMobile,
  setEmail,
  setLoginMethod,
}) => {
  const { t } = useTranslations();
  const { toggle } = useIntercom();

  if (loginId) {
    return (
      <>
        <PageWrapper>
          <InteractiveContainer
            className="bg-grey-100 absolute top-8 left-8 flex h-10 w-10 items-center justify-center rounded-lg"
            onClick={() => {
              setLoginId(null);
              setOtp("");
            }}
          >
            <ArrowIcon />
          </InteractiveContainer>
          <span className="font-secondary text-dark text-[32px] font-medium leading-8">
            {t({
              id: "auth-guard-component.enter-otp",
              defaultMessage: "Enter OTP",
            })}
          </span>
          <span className="text-grey-900 mt-3 text-sm leading-4">
            {loginMethod === "email"
              ? t(
                  {
                    id: "auth-guard-component.we-sent-it-to-email",
                    defaultMessage: "We sent it to the Email {email}",
                  },
                  {
                    email: (
                      <span className="text-primary-800">{loginValue}</span>
                    ),
                  }
                )
              : t(
                  {
                    id: "auth-guard-component.we-sent-it-to-phone-number",
                    defaultMessage:
                      "We sent it to the Phone Number {phoneNumber}",
                  },
                  {
                    phoneNumber: (
                      <span className="text-primary-800">{loginValue}</span>
                    ),
                  }
                )}
          </span>
          <div className="mt-16 flex-1">
            <OTPVerificationForm
              onResend={handleRequestOtp}
              onSubmit={handleVerifyOtp}
              className="p-0"
            />
          </div>
        </PageWrapper>
        <PageLoaderFull isTransparent isActive={isVerifyOtpLoading} />
        <Fab
          onClick={toggle}
          icon={<HelpIcon />}
          variant="primary"
          className="bg-primary fixed bottom-10 right-10 h-14 w-14"
        />
      </>
    );
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleRequestOtp();
        }}
      >
        <PageWrapper>
          {/* <div className="mb-10">
          <div className="mx-auto h-10 w-10 rounded-full bg-orange-500" />
          <span className="font-secondary mt-2 text-sm text-[#4F4F4F]">
            Workspace Name
          </span>
        </div> */}
          <span className="font-secondary text-dark mt-10 text-[32px] font-medium leading-8">
            {t({
              id: "auth-guard-component.task-updates",
              defaultMessage: "Task Updates",
            })}
          </span>
          <span className="text-grey-900 mt-3 text-sm leading-4">
            {t(
              {
                id: "auth-guard-component.enter-email-or-phone-to-send-otp",
                defaultMessage:
                  "You need to login to see the Task Updates. Please enter your <highlight>email</highlight> or <highlight>phone number</highlight> to which we'll send you an OTP code",
              },
              {
                highlight: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary-800">{text}</span>
                ),
              }
            )}
          </span>
          <div className="mt-[72px] flex">
            <MethodTab
              title={t({
                id: "auth-guard-component.email",
                defaultMessage: "Email",
              })}
              onClick={() => setLoginMethod("email")}
              isChecked={loginMethod === "email"}
            />
            <MethodTab
              title={t({
                id: "auth-guard-component.phone-number",
                defaultMessage: "Phone Number",
              })}
              onClick={() => setLoginMethod("mobile")}
              isChecked={loginMethod === "mobile"}
            />
          </div>
          <div className="mt-16 w-full">
            {loginMethod === "email" ? (
              <TextField
                placeholder={t({
                  id: "auth-guard-component.email",
                  defaultMessage: "Email",
                })}
                isFullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            ) : (
              <PhoneInput
                value={mobile}
                placeholder={t({
                  id: "auth-guard-component.mobile-number",
                  defaultMessage: "Mobile Number",
                })}
                onChange={setMobile}
              />
            )}
          </div>
          <Button
            className="mt-auto"
            fullWidth
            isDisabled={isRequestOtpLoading || !isLoginFormValid}
            type="submit"
          >
            {isRequestOtpLoading ? (
              <LoadingSpinner size="sm" />
            ) : (
              <span>
                {t({
                  id: "common.continue",
                  defaultMessage: "Continue",
                })}
              </span>
            )}
          </Button>
        </PageWrapper>
      </form>
      <Fab
        onClick={toggle}
        icon={<HelpIcon />}
        variant="primary"
        className="bg-primary fixed bottom-10 right-10 h-14 w-14"
      />
    </>
  );
};
