import { User } from "@jugl-web/rest-api";
import {
  TaskActivity,
  UpdatedActionChanges,
  stripTaskDescriptionPrefix,
} from "@jugl-web/rest-api/tasks";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import {
  addSearchParamsToURL,
  getUniqueId,
  joinReactNodes,
  trimHTML,
  useTranslations,
} from "@jugl-web/utils";
import { isoToLocalDate } from "@jugl-web/utils/date-time";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { joinReactNodesInHumanReadableForm } from "@jugl-web/utils/utils/joinReactNodes";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import format from "date-fns/format";
import { ReactNode, useCallback, useMemo } from "react";
import { TransformedActivity } from "../utils";
import { useTaskPriorities } from "./useTaskPriorities";
import { useTaskStatuses } from "./useTaskStatuses";

export interface ParsedTaskActivity {
  id: string;
  message: ReactNode;
  rating?: { rate: number; tags: string[] };
  raw: TaskActivity;
  user: {
    username: string;
    avatarUrl: string | null;
    isExternalClient?: boolean;
  };
}

type SupportedTaskChanges = Omit<
  Required<UpdatedActionChanges>,
  "old_custom_fields"
>;
type TaskChangeKeyToRendererMap = {
  [Key in keyof SupportedTaskChanges]: (
    arg: SupportedTaskChanges[Key],
    transformedActivity?: TransformedActivity
  ) => ReactNode;
};

interface UseTaskActivityParserOptions {
  entityId: string | undefined;
  meUser: User | undefined;
  skipRemote?: boolean;
}

// TODO: Remove and use useTaskLogParser instead
export const useTaskActivityParser = ({
  entityId,
  meUser,
  skipRemote,
}: UseTaskActivityParserOptions) => {
  const { getStatusDetailsById } = useTaskStatuses({ entityId, skipRemote });
  const { getPriorityDetailsById } = useTaskPriorities();
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const taskChangeKeyToRenderer: TaskChangeKeyToRendererMap = useMemo(
    () => ({
      name: (name) =>
        name
          ? t(
              {
                id: "tasks-page.updated-task-name-activity",
                defaultMessage: "updated task name to {name}",
              },
              {
                name: <b>{name}</b>,
              }
            )
          : t({
              id: "tasks-page.removed-task-name-activity",
              defaultMessage: "removed task name",
            }),
      desc: (description) =>
        description
          ? t(
              {
                id: "tasks-page.updated-task-description-activity",
                defaultMessage: "updated task description to {description}",
              },
              {
                description: (
                  <b>{trimHTML(stripTaskDescriptionPrefix(description))}</b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-task-description-activity",
              defaultMessage: "removed task description",
            }),
      priority: (priority) =>
        t(
          {
            id: "tasks-page.updated-task-priority-activity",
            defaultMessage: "updated task priority to {priority}",
          },
          { priority: <b>{getPriorityDetailsById(priority).shortLabel}</b> }
        ),
      board: (board) =>
        board
          ? t(
              {
                id: "tasks-page.moved-task-activity",
                defaultMessage: "moved task to {board}",
              },
              { board: <b>{board.name}</b> }
            )
          : t({
              id: "tasks-page.removed-task-board-activity",
              defaultMessage: "removed task from board",
            }),
      label: (label) =>
        label
          ? t(
              {
                id: "tasks-page.updated-task-label-activity",
                defaultMessage: "updated task label to {label}",
              },
              { label: <b>{label.text}</b> }
            )
          : t({
              id: "tasks-page.removed-task-label-activity",
              defaultMessage: "removed task label",
            }),
      due_at: (dueDate) =>
        dueDate
          ? t(
              {
                id: "tasks-page.updated-due-date-activity",
                defaultMessage: "updated due date to {dueDate}",
              },
              {
                dueDate: (
                  <b>
                    {format(isoToLocalDate(dueDate), "MMM dd, hh:mm a", {
                      locale: dateLocale,
                    })}
                  </b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-due-date-activity",
              defaultMessage: "removed due date",
            }),
      status: (statusId) =>
        t(
          {
            id: "tasks-page.updated-task-status-activity",
            defaultMessage: "updated task status to {status}",
          },
          { status: <b>{getStatusDetailsById(statusId).label}</b> }
        ),
      duration: (duration) => {
        const { hours, minutes } =
          convertMillisecondsToTimeComponents(duration);

        return t(
          {
            id: "tasks-page.updated-time-spent-activity",
            defaultMessage:
              "updated time spent on a task to <b>{hours}h {minutes}min</b>",
          },
          {
            hours,
            minutes,
            b: (text: (string | JSX.Element)[]) => <b>{text}</b>,
          }
        );
      },
      assignee_added: (assignees) =>
        t(
          {
            id: "tasks-page.assigned-users-activity",
            defaultMessage: "assigned {users} to the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      assignee_removed: (assignees) =>
        t(
          {
            id: "tasks-page.unassigned-users-activity",
            defaultMessage: "unassigned {users} from the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      checklist_added: (items) =>
        t(
          {
            id: "tasks-page.added-checklist-items-activity",
            defaultMessage:
              "added {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_updated: (items) =>
        t(
          {
            id: "tasks-page.updated-checklist-items-activity",
            defaultMessage:
              "updated {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.name || item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_rearranged: () =>
        t({
          id: "tasks-page.rearranged-checklist-items-activity",
          defaultMessage: "has rearranged checklist items",
        }),
      checklist_deleted: (items) =>
        t(
          {
            id: "tasks-page.removed-checklist-items-activity",
            defaultMessage:
              "removed {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      attachment_added: (attachment) =>
        t(
          {
            id: "tasks-page.added-attachment-activity",
            defaultMessage: "added attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_deleted: (attachment) =>
        t(
          {
            id: "tasks-page.removed-attachment-activity",
            defaultMessage: "removed attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_renamed: (attachment) =>
        t(
          {
            id: "tasks-page.renamed-attachment-activity",
            defaultMessage: "renamed attachment {oldName} to {newName}",
          },
          {
            oldName: <b>{attachment.old_file_name}</b>,
            newName: <b>{attachment.file_name}</b>,
          }
        ),
      customer: () =>
        t({
          id: "tasks-page.updated-task-customer-activity",
          defaultMessage: "updated task customer",
        }),
      reminder: () =>
        t({
          id: "tasks-page.updated-task-reminder-activity",
          defaultMessage: "updated task reminder",
        }),
      recurrence: () =>
        t({
          id: "tasks-page.updated-task-recurrence-settings-activity",
          defaultMessage: "updated task recurrence settings",
        }),
      has_chklist_chk: (isInSpecificOrder) =>
        isInSpecificOrder
          ? t({
              id: "tasks-page.updated-checklist-specific-order-activity",
              defaultMessage:
                "updated checklist to be completed in specific order",
            })
          : t({
              id: "tasks-page.updated-checklist-any-order-activity",
              defaultMessage: "updated checklist to be completed in any order",
            }),
      custom_fields: (_, transformed) => {
        const isRemoved = transformed?.custom_fields?.isRemoved;
        if (!transformed?.custom_fields?.name) {
          return t({
            id: "tasks-page.updated-task-custom-field-activity",
            defaultMessage: "updated task custom field",
          });
        }

        if (
          transformed.custom_fields.isRemoved ||
          !transformed.custom_fields.value
        ) {
          return t(
            {
              id: "tasks-page.removed-custom-fields-activity",
              defaultMessage: "removed {customField}",
            },
            {
              customField: transformed.custom_fields.name,
            }
          );
        }

        return !isRemoved
          ? t(
              {
                id: "tasks-page.updated-custom-fields-activity",
                defaultMessage: "updated {customField} to {value}",
              },
              {
                customField: transformed.custom_fields.name,
                value: (
                  <b>
                    <Linkify>{transformed.custom_fields.value}</Linkify>
                  </b>
                ),
              }
            )
          : t(
              {
                id: "tasks-page.updated-custom-fields-to-none-activity",
                defaultMessage: "updated {customField} to <b>none<b>",
              },
              {
                customField: transformed.custom_fields.name,
                b: (text: (string | JSX.Element)[]) => <b>{text}</b>,
              }
            );
      },
    }),
    [getPriorityDetailsById, getStatusDetailsById, t, dateLocale]
  );

  const taskCreatedRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-created-task-activity",
          defaultMessage: "{username} created this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskRestoredRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-restored-task-activity",
          defaultMessage: "{username} restored this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskArchivedRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-archived-task-activity",
          defaultMessage: "{username} archived this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskDeletedRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-removed-task-activity",
          defaultMessage: "{username} removed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskViewedRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-viewed-task-activity",
          defaultMessage: "{username} viewed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskDownloadedRenderer = useCallback(
    (username: string, fileName: string) =>
      t(
        {
          id: "tasks-page.attachment-downloaded-activity",
          defaultMessage: "{username} downloaded attachment {fileName}",
        },
        {
          username: <b>{username}</b>,
          fileName: <b>{fileName}</b>,
        }
      ),
    [t]
  );

  const taskCommentedRenderer = useCallback(
    (content: string) => (
      <Linkify>
        <Mentionify>{content}</Mentionify>
      </Linkify>
    ),
    []
  );

  const taskUpdatedRenderer = useCallback(
    (username: string, updatedAttributesMessage: ReactNode): ReactNode | null =>
      joinReactNodes([<b>{username}</b>, updatedAttributesMessage], " "),
    []
  );

  const taskUpdatedAttributeRenderer = useCallback(
    <
      TField extends keyof SupportedTaskChanges,
      TValue extends SupportedTaskChanges[TField]
    >(
      field: TField,
      value: TValue,
      transformedActivity?: TransformedActivity
    ): ReactNode | null =>
      taskChangeKeyToRenderer[field]?.(value, transformedActivity) || null,
    [taskChangeKeyToRenderer]
  );

  const parseTaskActivity = useCallback(
    (
      activity: TaskActivity,
      transformedActivity?: TransformedActivity
    ): ParsedTaskActivity | null => {
      const id = getUniqueId();
      const user =
        activity.action_by.user_id === meUser?.id
          ? meUser?.profile
          : activity.action_by;
      const username =
        [user?.first_name, user?.last_name]
          .filter((item) => !!item)
          .join(" ") || "Jugl User";
      const { action } = activity.action_details;
      const guestAvatarUrl =
        user && "logo" in user && user.logo
          ? addSearchParamsToURL(user.logo, {
              t: user.updated_at,
            })
          : null;
      if (action === "guest_feedback") {
        return {
          id,
          message: taskCommentedRenderer(
            activity.action_details?.changes.content.feedback
          ),
          rating: {
            rate: activity.action_details?.changes.content.rating,
            tags: activity.action_details?.changes.content.hashtag,
          },
          raw: activity,
          user: {
            username,
            avatarUrl: guestAvatarUrl,
          },
        };
      }

      if (
        action === "viewed" ||
        action === "commented" ||
        action === "guest_comment" ||
        action === "guest_comment_reply"
      ) {
        return {
          id,
          message:
            action === "viewed"
              ? taskViewedRenderer(username)
              : taskCommentedRenderer(
                  activity.action_details?.changes?.content
                ),
          raw: activity,
          user: {
            username,
            avatarUrl:
              action === "guest_comment"
                ? guestAvatarUrl
                : user?.img
                ? addSearchParamsToURL(user.img, {
                    t: user.updated_at,
                  })
                : null,
          },
        };
      }

      if (action === "guest_opened") {
        return {
          id,
          message: taskViewedRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: guestAvatarUrl,
          },
        };
      }

      if (
        action === "created" &&
        activity?.action_by.user_id === "00000000-0000-0000-0000-000000000000"
      ) {
        return {
          id,
          message: t(
            {
              id: "tasks-page.external-user-created-task-activity",
              defaultMessage:
                "{username} created this task by submitting the order form",
            },
            {
              username: (
                <b>
                  {t({
                    id: "common.external-client",
                    defaultMessage: "External client",
                  })}
                </b>
              ),
            }
          ),
          raw: activity,
          user: {
            username,
            avatarUrl: null,
            isExternalClient: true,
          },
        };
      }

      if (action === "downloaded" || action === "guest_downloaded") {
        return {
          id,
          message: taskDownloadedRenderer(
            username,
            activity.action_details?.file_name
          ),
          raw: activity,
          user: {
            username,
            avatarUrl:
              guestAvatarUrl ||
              (user?.img
                ? addSearchParamsToURL(user.img, {
                    t: user.updated_at,
                  })
                : null),
          },
        };
      }

      if (action === "created" || action === "deleted") {
        return {
          id,
          message:
            action === "created"
              ? taskCreatedRenderer(username)
              : taskDeletedRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: user?.img
              ? addSearchParamsToURL(user.img, {
                  t: user.updated_at,
                })
              : null,
          },
        };
      }

      if (action === "restored" || action === "archived") {
        return {
          id,
          message:
            action === "restored"
              ? taskRestoredRenderer(username)
              : taskArchivedRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: user?.img
              ? addSearchParamsToURL(user.img, {
                  t: user.updated_at,
                })
              : null,
          },
        };
      }

      const { changes } = activity.action_details;

      const updatedAttributesMessages = Object.keys(changes).reduce<
        ReactNode[]
      >((acc, key) => {
        const field = key as keyof SupportedTaskChanges;

        const value = changes[
          field
        ] as SupportedTaskChanges[keyof SupportedTaskChanges];

        let message: ReactNode;

        try {
          message = taskUpdatedAttributeRenderer(
            field,
            value,
            transformedActivity
          );
        } catch {
          // Do nothing
        }

        if (!message) {
          // eslint-disable-next-line no-console
          console.warn("Couldn't parse the following task activity change", {
            action,
            field,
            value,
          });

          return acc;
        }

        return [...acc, message];
      }, []);

      if (updatedAttributesMessages.length === 0) {
        return null;
      }

      return {
        id,
        message: taskUpdatedRenderer(
          username,
          joinReactNodesInHumanReadableForm(updatedAttributesMessages)
        ),
        raw: activity,
        user: {
          username,
          avatarUrl: user?.img
            ? addSearchParamsToURL(user.img, {
                t: user.updated_at,
              })
            : null,
        },
      };
    },
    [
      meUser?.id,
      meUser?.profile,
      taskUpdatedRenderer,
      taskCommentedRenderer,
      taskViewedRenderer,
      t,
      taskDownloadedRenderer,
      taskCreatedRenderer,
      taskDeletedRenderer,
      taskRestoredRenderer,
      taskUpdatedAttributeRenderer,
      taskArchivedRenderer,
    ]
  );

  const parseTaskActivities = useCallback(
    (activities: TaskActivity[]) =>
      activities.reduce<ParsedTaskActivity[]>((acc, activity) => {
        const output = parseTaskActivity(activity);

        if (output) {
          return [...acc, output];
        }

        return acc;
      }, []),
    [parseTaskActivity]
  );

  return { parseTaskActivity, parseTaskActivities };
};
