import {
  useTranslations,
  apiUTCToLocalDateTime,
  priceDisplay,
} from "@jugl-web/utils";
import { ReactNode } from "react";
import { InvoiceGuestLogModel } from "@jugl-web/rest-api/orders/models/InvoiceGuestLog";
import { format } from "date-fns";
import { ReactComponent as GuestAvatarIcon } from "./assets/guest-avatar.svg";

export const InvoiceHistoryItem = ({
  log,
  currency,
  units,
}: {
  log: InvoiceGuestLogModel;
  currency: string;
  units: string;
}) => {
  const { t } = useTranslations();

  const formattedDate = format(
    apiUTCToLocalDateTime(log.inserted_at),
    "MMM d, h:mm a"
  );

  const logs = {
    reopened: t({
      id: "guest-task-page.order-log-reopened",
      defaultMessage: "Order was reopened",
    }),
    line_item_added: t(
      {
        id: "guest-task-page.invoice-log-line-item-added",
        defaultMessage: "{category} <b>{itemName}</b> was added to the Order",
      },
      {
        category: log.info.category === "service" ? "Service" : "Item",
        itemName: log.info.item_name,
        b: (chunks: ReactNode) => (
          <b className="text-dark font-semibold">{chunks}</b>
        ),
      }
    ),
    line_item_removed: t(
      {
        id: "guest-task-page.invoice-log-line-item-removed",
        defaultMessage:
          "{category} <b>{itemName}</b> was removed from the Order",
      },
      {
        category: log.info.category === "service" ? "Service" : "Item",
        itemName: log.info.item_name,
        b: (chunks: ReactNode) => (
          <b className="text-dark  font-semibold">{chunks}</b>
        ),
      }
    ),
    line_item_updated: t(
      {
        id: "guest-task-page.invoice-log-line-item-updated",
        defaultMessage:
          "<medium>{changedParameter}</medium> of {category} <b>{itemName}</b> was changed from <medium>{from}</medium> to <medium>{to}</medium>.",
      },
      {
        category: log.info.category === "service" ? "service" : "item",
        itemName: log.info.item_name,
        changedParameter: "new_price" in log.info ? "Price" : "Quantity",
        from:
          "prev_price" in log.info
            ? priceDisplay(
                log.info.prev_price ? log.info.prev_price * 100 : 0,
                currency
              )
            : `${log.info.prev_qty} ${log.info.unit || units}`,
        to:
          "new_price" in log.info
            ? priceDisplay(
                log.info.new_price ? log.info.new_price * 100 : 0,
                currency
              )
            : `${log.info.new_qty} ${units}`,
        b: (chunks: ReactNode) => (
          <b className="text-dark break-all font-semibold">{chunks}</b>
        ),
        medium: (chunks: ReactNode) => (
          <span className="font-medium">{chunks}</span>
        ),
      }
    ),
  };

  return (
    <div className="flex items-start gap-3 py-6">
      <div className="h-[32px] w-[32px]">
        <GuestAvatarIcon />
      </div>
      <div className="text-dark-600 flex flex-col gap-1.5">
        <div className="text-sm">
          {logs[log.action as keyof typeof logs] || log.action}
        </div>
        <div className="font-secondary text-grey-900 text-xs">
          {formattedDate}
        </div>
      </div>
    </div>
  );
};
