/* eslint-disable @typescript-eslint/lines-between-class-members, class-methods-use-this, no-bitwise */
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  IS_BOLD,
  IS_ITALIC,
  IS_STRIKETHROUGH,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";

export type SerializedVariableNode = Spread<
  { variableName: string },
  SerializedTextNode
>;

const convertVariableElement = (
  element: HTMLElement
): DOMConversionOutput | null => {
  const { textContent } = element;

  if (!textContent) {
    return null;
  }

  return {
    node: $createVariableNode(textContent),
  };
};

export class VariableNode extends TextNode {
  __variableName: string;

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variableName, node.__key);
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(serializedNode.variableName);

    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (node) => {
        if (!node.dataset.lexicalVariableNode) {
          return null;
        }

        return {
          conversion: convertVariableElement,
          priority: 1,
        };
      },
    };
  }

  constructor(variableName: string, key?: string) {
    super(`{{${variableName}}}`, key);
    this.__variableName = variableName;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.classList.add(config.theme.variable);
    return element;
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      variableName: this.__variableName,
      version: 1,
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.dataset.lexicalVariableNode = "true";
    element.textContent = this.__variableName;

    const format = this.getFormat();

    if (format & IS_BOLD) element.classList.add("font-bold");
    if (format & IS_ITALIC) element.classList.add("italic");
    if (format & IS_STRIKETHROUGH) element.classList.add("line-through");

    element.classList.add("text-primary-800");

    return { element };
  }

  isTextEntity(): boolean {
    return false;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export const $createVariableNode = (variableName: string): VariableNode => {
  const variableNode = new VariableNode(variableName);

  variableNode.setMode("token").toggleDirectionless();

  return $applyNodeReplacement(variableNode);
};

export const $createVariableNodeBasedOnTextNode = (
  variableName: string,
  textNode: TextNode
) => {
  const variableNode = $createVariableNode(variableName);

  variableNode.setFormat(textNode.getFormat());

  return $applyNodeReplacement(variableNode);
};

export const $isVariableNode = (
  node: LexicalNode | null | undefined
): node is VariableNode => node instanceof VariableNode;
