import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { PhoneInputValue } from "../PhoneInput";
import { Country } from "../types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries: Country[] = require("countries-phone-masks");

export const customerPhoneStringToInputValue = (
  phoneStr: string
): PhoneInputValue => {
  const [iso, code, phone] = phoneStr.split(",");
  const country = countries.find((item) => item.iso === iso);

  let phoneNumber;
  try {
    phoneNumber = parsePhoneNumber(`${code}${phone}`, iso as CountryCode);
  } catch (error) {
    //
  }

  return {
    iso: country?.iso || "IN",
    code,
    phone,
    isValid: /000\d\d\d\d000/g.test(phone) || phoneNumber?.isValid() || false,
  };
};
