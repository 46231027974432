import { HookOutOfContextError } from "@jugl-web/utils/errors";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { EntityPropsApi } from "./features/entity-props/entityPropsApi";
import { EntitySpacesApi } from "./features/entity-spaces/entitySpacesApi";
import { TasksApi } from "./features/tasks";
import { UsersApi } from "./features/users";
import { WorkspacesApi } from "./features/workspaces";
import { CustomerApi } from "./features/customer";
import { CustomerFormFieldApi } from "./features/customer-form-field";
import { TasksCommentsApi } from "./features/tasks-comments/tasksCommentsApi";
import { DriveApi } from "./features/drive";
import { TasksTemplatesApi } from "./features/tasks-templates";
import { SupersetApi } from "./features/superset";
import { ModuleNotificationsApi } from "./features/module-notifications";
import { AuthApi } from "./features/auth/authApi";
import { EntitiesApi } from "./features/entities/entitiesApi";
import { ReportsApi } from "./features/reports";
import { LinksApi } from "./features/links";
import { OrdersApi } from "./features/orders";
import { IntegrationsApi } from "./features/integrations";

interface RestApiContextValue {
  apiBaseUrl: string;
  tasksApi: TasksApi;
  tasksCommentsApi: TasksCommentsApi;
  tasksTemplatesApi: TasksTemplatesApi;
  usersApi: UsersApi;
  workspacesApi: WorkspacesApi;
  entityPropsApi: EntityPropsApi;
  entitySpacesApi: EntitySpacesApi;
  customersApi: CustomerApi;
  customersFormFieldApi: CustomerFormFieldApi;
  driveApi: DriveApi;
  supersetApi: SupersetApi;
  moduleNotificationsApi: ModuleNotificationsApi;
  authApi: AuthApi;
  entitiesApi: EntitiesApi;
  reportsApi: ReportsApi;
  linksApi: LinksApi;
  ordersApi: OrdersApi;
  integrationsApi: IntegrationsApi;
}

const RestApiContext = createContext<RestApiContextValue | null>(null);

export const RestApiProvider: FC<
  PropsWithChildren<{ apis: Partial<RestApiContextValue>; apiBaseUrl: string }>
> = ({ children, apis, apiBaseUrl }) => {
  const value = useMemo(
    () => ({ ...apis, apiBaseUrl } as RestApiContextValue),
    [apis, apiBaseUrl]
  );
  return (
    <RestApiContext.Provider value={value}>{children}</RestApiContext.Provider>
  );
};

export const useRestApiProvider = () => {
  const context = useContext(RestApiContext);

  if (!context) {
    throw new HookOutOfContextError("useRestApiProvider", "RestApiContext");
  }

  return context;
};
