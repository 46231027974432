import { useTranslations } from "@jugl-web/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { FC, ForwardedRef, useEffect, useImperativeHandle } from "react";
import { MentionTextareaHandle } from "../types";
import { parseTextWithMentions, toTextWithMentions } from "../utils";

export interface MentionTextareaControllerPluginProps {
  handle: ForwardedRef<MentionTextareaHandle>;
  onRawTextChange?: (rawText: string) => void;
}

export const MentionTextareaControllerPlugin: FC<
  MentionTextareaControllerPluginProps
> = ({ handle, onRawTextChange }) => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslations();

  useImperativeHandle(handle, () => ({
    saveToJSON: () => editor.getEditorState().toJSON(),
    readFromJSON: (serializedState) =>
      editor.setEditorState(editor.parseEditorState(serializedState)),
    getRichText: () => toTextWithMentions(editor.getEditorState().toJSON()),
    setRichText: (text) =>
      editor.setEditorState(
        editor.parseEditorState(parseTextWithMentions(text, t))
      ),
    focus: () => {
      editor.focus();
    },
    insertTextAtSelection: (text: string) => {
      editor.update(() => {
        const selection = $getSelection();
        selection?.insertText(text);
      });
    },
  }));

  useEffect(() => {
    if (!onRawTextChange) {
      return undefined;
    }

    const unsubscribe = editor.registerTextContentListener(onRawTextChange);

    return unsubscribe;
  }, [editor, onRawTextChange]);

  return null;
};
