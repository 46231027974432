import format from "date-fns/format";
import { TFunction } from "../hooks";

export const humanizePastDate = (
  date: Date,
  t: TFunction,
  dateLocale?: Locale
) => {
  const diff = Date.parse(new Date().toUTCString()) - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  if (seconds < 60) {
    return t({
      id: "common.just-now",
      defaultMessage: "Just now",
    });
  }
  if (minutes < 60) {
    return t(
      {
        id: "common.date-minutes-ago",
        defaultMessage:
          "{minutes} {minutes, plural, one {minute} other {minutes}} ago",
      },
      {
        minutes,
      }
    );
  }
  if (hours < 24) {
    return t(
      {
        id: "common.date-hours-ago",
        defaultMessage: "{hours} {hours, plural, one {hour} other {hours}} ago",
      },
      {
        hours,
      }
    );
  }
  return format(date, "dd MMM yyyy, hh:mm a", { locale: dateLocale });
};
