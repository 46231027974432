import { FC, ReactNode, Children, isValidElement, cloneElement } from "react";
import { cx } from "../cx";

const MENTIONS_REGEXP = /@<([^>]+?)>\[([^\]]+?)\]/g;

interface MentionRendererOptions {
  userId: string;
  username: string;
  isUnstyled?: boolean;
}

// userId might be useful in the future
const mentionRenderer = ({ username, isUnstyled }: MentionRendererOptions) => (
  <span className={cx(!isUnstyled && "text-primary-500 font-bold")}>
    {username}
  </span>
);

const mentionifyString = (text: string, isUnstyled?: boolean) => {
  const parts = text.split(MENTIONS_REGEXP);

  return parts.map((part, index) => {
    // check if index matches the first captured group (userId)
    if (index % 3 === 1) {
      const [userId, username] = [parts[index], parts[index + 1]];

      return mentionRenderer({ userId, username, isUnstyled });
    }

    // skip the second captured group (username) index as it was already
    // processed in the previous iteration
    if (index % 3 === 2) {
      return null;
    }

    return part;
  });
};

const mentionifyNode = (node: ReactNode, isUnstyled?: boolean) => {
  if (typeof node === "string") {
    return mentionifyString(node, isUnstyled);
  }

  // traverse children of the node recursively
  if (isValidElement(node)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nodeChildren: any = node.props.children
      ? Children.map(node.props.children, (child) =>
          mentionifyNode(child, isUnstyled)
        )
      : node.props.children;

    return cloneElement(node, {}, nodeChildren);
  }

  if (node === null || node === undefined) {
    return node;
  }

  return node.toString();
};

export interface MentionifyProps {
  children: ReactNode;
  isUnstyled?: boolean;
}

export const Mentionify: FC<MentionifyProps> = ({ children, isUnstyled }) => (
  <>{Children.map(children, (node) => mentionifyNode(node, isUnstyled))}</>
);
