import { FC } from "react";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as JuglLogo } from "./assets/jugl-logo.svg";

export const WebTaskPageHeader: FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslations();

  return (
    <HeaderBreadcrumbs
      icon={<JuglLogo />}
      items={[
        {
          title:
            title === undefined
              ? t({
                  id: "guest-task-page.task-updates",
                  defaultMessage: "Task updates",
                })
              : title,
        },
      ]}
    />
  );
};
