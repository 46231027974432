import { TaskChecklistItem } from "@jugl-web/rest-api/tasks";
import { TaskTemplateChecklistItem } from "@jugl-web/rest-api/tasks-templates";
import { getUniqueId, isoToLocalDate } from "@jugl-web/utils";
import { Subject } from "rxjs";
import { getDueDateBasedOnDays, getDueDateInDays } from "../../utils";
import { TaskChecklistItem as InternalTaskChecklistItem } from "./types";

export const produceNewItem = (): InternalTaskChecklistItem => ({
  id: getUniqueId(),
  text: "",
  isCompleted: false,
  completedAt: null,
  assigneeId: null,
  dueDate: null,
});

export const sortItemsByCompleted = (items: InternalTaskChecklistItem[]) =>
  [...items].sort(
    (itemA, itemB) => Number(itemB.isCompleted) - Number(itemA.isCompleted)
  );

interface GetSpecificOrderCompletionStateOptions {
  isCompleteInSpecificOrder: boolean;
  item: InternalTaskChecklistItem;
  previousItem: InternalTaskChecklistItem | undefined;
  nextItem: InternalTaskChecklistItem | undefined;
}

export interface SpecificOrderCompletionState {
  isDisabled: boolean;
  isCurrent: boolean;
}

export const getSpecificOrderCompletionState = ({
  isCompleteInSpecificOrder,
  item,
  previousItem,
  nextItem,
}: GetSpecificOrderCompletionStateOptions): SpecificOrderCompletionState => {
  if (!isCompleteInSpecificOrder) {
    return {
      isDisabled: false,
      isCurrent: false,
    };
  }

  return {
    isDisabled:
      previousItem?.isCompleted === false || nextItem?.isCompleted === true,
    isCurrent:
      (!previousItem || previousItem.isCompleted) &&
      !item.isCompleted &&
      (!nextItem || !nextItem.isCompleted),
  };
};

export const taskChecklistItemAdapters = {
  toInternalModel: (item: TaskChecklistItem): InternalTaskChecklistItem => ({
    id: item.id,
    text: item.name,
    isCompleted: item.is_completed,
    completedAt: item.completed_at
      ? new Date(isoToLocalDate(item.completed_at))
      : null,
    assigneeId: item.user_id,
    dueDate: item.due_at ? new Date(isoToLocalDate(item.due_at)) : null,
  }),
  toBackendModel: (
    item: InternalTaskChecklistItem,
    index: number
  ): TaskChecklistItem => ({
    id: item.id,
    order: index + 1,
    name: item.text,
    is_completed: item.isCompleted,
    completed_at: item.completedAt?.toISOString() || null,
    user_id: item.assigneeId,
    due_at: item.dueDate?.toISOString() || null,
  }),
};

export const templateChecklistItemAdapters = {
  toInternalModel: (
    item: TaskTemplateChecklistItem
  ): InternalTaskChecklistItem => ({
    id: getUniqueId(),
    text: item.name,
    isCompleted: false,
    completedAt: null,
    assigneeId: item.user_id || null,
    dueDate: item.due_in !== null ? getDueDateBasedOnDays(item.due_in) : null,
  }),
  toBackendModel: (item: InternalTaskChecklistItem, index: number) => ({
    order: index + 1,
    name: item.text,
    due_in: item.dueDate ? getDueDateInDays(item.dueDate) : null,
    user_id: item.assigneeId,
  }),
};

export const saveTaskChecklist$: Subject<{ subtaskId: string }> = new Subject();
