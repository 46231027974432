import { OrderItemsTable } from "@jugl-web/domain-resources/tasks/components/OrderItemsTable/OrderItemsTable";
import { OrderResponseDto } from "@jugl-web/rest-api/orders/models/OrderResponse";
import { OrderInfo } from "@jugl-web/domain-resources/tasks/components/OrderInfo/OrderInfo";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MobileInvoiceItem } from "@jugl-web/domain-resources/tasks/components/MobileInvoiceItem";
import {
  priceDisplay,
  useTranslations,
  useAppVariant,
  cx,
} from "@jugl-web/utils";
import { Fragment } from "react";
import { LoadingAnimation } from "@jugl-web/ui-components";
import { DEFAULT_ENTITY_CURRENCY } from "@jugl-web/utils/consts";
import { ReactComponent as DocumentIcon } from "./assets/document.svg";
import { ReactComponent as CaseIcon } from "./assets/case.svg";

export const TaskOrderDetailsTab = ({
  orderResponse,
}: {
  orderResponse?: OrderResponseDto;
}) => {
  const { isMobile } = useAppVariant();
  const { ordersApi } = useRestApiProvider();
  const { data: initData } = ordersApi.useGuestInitQuery(
    orderResponse?.invoice?.entity_id
      ? { entityId: orderResponse.invoice.entity_id }
      : skipToken
  );
  const { t } = useTranslations();
  if (!orderResponse) {
    return <LoadingAnimation />;
  }
  if (isMobile && orderResponse?.invoice) {
    return (
      <div
        className={cx("px-4", {
          "px-0": isMobile,
        })}
      >
        <div
          className={cx(
            "font-secondary text-dark flex items-center gap-2 text-base font-medium",
            {
              "text-sm": isMobile,
            }
          )}
        >
          <DocumentIcon />
          {t({
            id: "tasks-page.order-details",
            defaultMessage: "Order Details",
          })}
        </div>
        <div className="font-secondary text-dark flex gap-2 pt-[18px] font-medium">
          <CaseIcon />
          {t({
            id: "tasks-page.items-services",
            defaultMessage: "Items / Services",
          })}
        </div>
        <div className="border-dark-100 mt-[18px] overflow-hidden rounded-[10px] border border-solid">
          {orderResponse?.invoice?.items.map((item) => (
            <Fragment key={item.inventory_id}>
              <MobileInvoiceItem
                item={item}
                currency={initData?.entity.currency || DEFAULT_ENTITY_CURRENCY}
              />
              <div className="bg-dark-100 h-px" />
            </Fragment>
          ))}
          <div className="text-dark-700 font-secondary ml-auto flex items-center justify-end gap-3 bg-white py-[18px] px-4 font-semibold leading-[140%]">
            <span>
              {t({
                id: "tasks-page.total",
                defaultMessage: "Total:",
              })}
            </span>
            <span className="text-dark font-medium tracking-[0.16px]">
              {initData &&
                priceDisplay(
                  orderResponse.invoice.total * 100,
                  initData?.entity.currency || DEFAULT_ENTITY_CURRENCY
                )}
            </span>
          </div>
        </div>
        <div
          className={cx("py-4", {
            "pt-8": isMobile,
          })}
        >
          {orderResponse && <OrderInfo orderResponse={orderResponse} />}
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-12">
      {orderResponse?.invoice && (
        <OrderItemsTable
          currency={initData?.entity.currency || DEFAULT_ENTITY_CURRENCY}
          invoice={orderResponse.invoice}
          isGuestApp
        />
      )}
      {orderResponse && <OrderInfo orderResponse={orderResponse} />}
    </div>
  );
};
