import { DetailedTask } from "@jugl-web/rest-api/tasks";
import {
  useTranslations,
  useSearchInput,
  cx,
  useAppVariant,
} from "@jugl-web/utils";
import React from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { SubTask } from "./components/SubTask";
import { ReactComponent as SubTasksIcon } from "./assets/sub-tasks.svg";

export const TaskSubTasksSection: React.FC<{
  task: DetailedTask;
}> = ({ task }) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();
  const { searchQuery, reset, inputProps } = useSearchInput();
  const items = task.checklist.filter(
    (item) =>
      !searchQuery ||
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      {!task.checklist.length ? (
        <div>
          {isMobile && (
            <div className="text-dark font-secondary flex gap-2 pb-4 text-sm">
              <SubTasksIcon />
              {t({
                id: "common.subtasks",
                defaultMessage: "Subtasks",
              })}
            </div>
          )}
          <span className="text-dark-500 text-sm">
            {t({
              id: "guest-task-page.no-sub-tasks-yet",
              defaultMessage: "No Sub-Tasks yet",
            })}
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-7">
          <div
            className={cx("w-[320px]", {
              "w-full": isMobile,
            })}
          >
            {isMobile && (
              <div className="text-dark font-secondary flex gap-2 pb-4 text-sm">
                <SubTasksIcon />
                {t({
                  id: "common.subtasks",
                  defaultMessage: "Subtasks",
                })}
              </div>
            )}
            <SearchInput
              variant="filled"
              color="grey"
              placeholder={t({
                id: "common.search",
                defaultMessage: "Search",
              })}
              {...inputProps}
              onClear={reset}
            />
          </div>
          <div>
            {items.map((item, idx, array) => (
              <SubTask
                key={item.id}
                name={item.name}
                isCompleted={item.is_completed}
                completedAt={item.completed_at}
                dueAt={item.due_at}
                isLast={idx === array.length - 1}
                searchQuery={searchQuery}
              />
            ))}
            {items.length === 0 && searchQuery && (
              <span className="font-secondary mb-0 ml-0 mr-0 mt-8 text-base leading-4 text-[#828282]">
                {t({
                  id: "guest-task-page.no-results",
                  defaultMessage: "No results 😔",
                })}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
