import {
  DialogPanel as HeadlessUIDialogPanel,
  Dialog as HeadlessUIDialog,
  Transition,
  TransitionChild,
  DialogBackdrop,
} from "@headlessui/react";
import { cx } from "@jugl-web/utils";
import { FC, Fragment, MutableRefObject, ReactNode } from "react";

export type DialogSize = "auto" | "full-width";

export interface DialogProps {
  children: ReactNode;
  isOpen: boolean;
  size?: DialogSize;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  className?: string;
  backdropClassName?: string;
  onClose: () => void;
  onTransitionEnd?: () => void;
}

const sizeToClasses: Record<DialogSize, string> = {
  auto: "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
  "full-width": "left-4 right-4 top-1/2 -translate-y-1/2",
};

export const Dialog: FC<DialogProps> = ({
  children,
  isOpen,
  size = "auto",
  initialFocus,
  className,
  backdropClassName,
  onClose,
  onTransitionEnd,
}) => (
  <Transition show={isOpen} as={Fragment}>
    <HeadlessUIDialog
      unmount
      onClose={onClose}
      initialFocus={initialFocus}
      className="jugl__border-box-component"
      onTransitionEnd={onTransitionEnd}
    >
      <TransitionChild
        as={Fragment}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogBackdrop
          className={cx(
            "fixed inset-0 z-50 bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]",
            backdropClassName
          )}
        />
      </TransitionChild>
      <TransitionChild
        as={Fragment}
        enter="transition duration-200"
        enterFrom="opacity-0 scale-90"
        enterTo="opacity-100 scale-100"
        leave="transition duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-90"
      >
        <HeadlessUIDialogPanel
          className={cx(
            "fixed z-[2000] rounded-lg bg-white",
            sizeToClasses[size],
            className
          )}
          style={{ maxHeight: "90vh" }}
        >
          {children}
        </HeadlessUIDialogPanel>
      </TransitionChild>
    </HeadlessUIDialog>
  </Transition>
);
