import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { MentionMenuOption } from "./utils";
import { ReactComponent as MentionsAllIcon } from "./icons/mentions-all.svg";

interface MentionItemProps {
  option: MentionMenuOption;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
}

export const MentionMenuItem: FC<MentionItemProps> = ({
  option,
  isSelected,
  onClick,
  onMouseEnter,
}) => {
  const { t } = useTranslations();
  return (
    <li
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      className={cx(
        "flex w-full items-center gap-3 px-4 py-1",
        isSelected && "bg-grey-100"
      )}
      tabIndex={-1}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {option.id === MENTIONS_ALL_ID ? (
        <MentionsAllIcon className="shrink-0" />
      ) : (
        <Avatar
          size="md"
          username={option.name}
          imageUrl={option.imageUrl}
          className="shrink-0"
        />
      )}
      <span className="text-dark">
        {option.id === MENTIONS_ALL_ID
          ? t({
              id: "common.all",
              defaultMessage: "All",
            })
          : option.name}
      </span>
    </li>
  );
};
