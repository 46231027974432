/* eslint-disable prefer-template */
import { useTranslations } from "@jugl-web/utils";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalTypeaheadMenuPlugin } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $createTextNode, TextNode } from "lexical";
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform/LoadingSpinner";
import { CaretPinnedMentionsList } from "./CaretPinnedMentionsList";
import { ContainerPinnedMentionsList } from "./ContainerPinnedMentionsList";
import { MentionMenuItem } from "./MentionMenuItem";
import { $createMentionNode, MentionNode } from "./MentionNode";
import { Mention, MentionListType } from "./types";
import { MentionMenuOption } from "./utils";

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNCTUATION + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNCTUATION +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const MENTIONS_REGEX = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}}))$`
);

export const checkForAtSignMentions = (
  text: string,
  minMatchLength: number
) => {
  const match = MENTIONS_REGEX.exec(text);

  if (!match) {
    return null;
  }

  // The strategy ignores leading whitespace but we need to know it's
  // length to add it to the leadOffset
  const maybeLeadingWhitespace = match[1];

  const matchingString = match[3];

  if (matchingString.length >= minMatchLength) {
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    };
  }

  return null;
};

interface MentionsPluginProps {
  mentions: Mention[];
  listType: MentionListType;
  containerRef: RefObject<HTMLDivElement>;
  hasMore?: boolean;
  isLoading?: boolean;
  onQueryChange?: (query: string) => void;
  loadMentions?: () => void;
}

export const MentionsPlugin: FC<MentionsPluginProps> = ({
  mentions,
  listType,
  containerRef,
  hasMore,
  isLoading,
  onQueryChange,
  loadMentions,
}) => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState("");
  const { t } = useTranslations();
  const { ref: moreMentionsLoadingRef, inView } = useInView();

  const menuOptions = useMemo(
    () =>
      mentions.map(
        (mention) =>
          new MentionMenuOption(
            mention.userId,
            mention.userId === MENTIONS_ALL_ID
              ? t({ id: "common.all", defaultMessage: "All" })
              : mention.name,
            mention.imageUrl
          )
      ),
    [mentions, t]
  );

  const matchingMenuOptions = useMemo(
    () => menuOptions.filter((option) => option.matchesFilter(queryString)),
    [menuOptions, queryString]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionMenuOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.id,
          selectedOption.name
        );

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        const emptySpaceNode = $createTextNode(" ");

        mentionNode.insertAfter(emptySpaceNode);
        emptySpaceNode.select();

        closeMenu();
      });
    },
    [editor]
  );

  useEffect(() => {
    if (!editor.hasNode(MentionNode)) {
      throw new Error("MentionsPlugin: MentionNode not registered on editor");
    }
  }, [editor]);

  useEffect(() => {
    if (inView) {
      loadMentions?.();
    }
  }, [inView, loadMentions]);

  return (
    <LexicalTypeaheadMenuPlugin<MentionMenuOption>
      onQueryChange={(query) => {
        setQueryString(query ?? "");
        onQueryChange?.(query ?? "");
      }}
      onSelectOption={onSelectOption}
      triggerFn={(text) => checkForAtSignMentions(text, 0)}
      options={matchingMenuOptions}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) => {
        const anchorElement =
          listType === "container-pinned"
            ? containerRef.current
            : anchorElementRef.current;

        if (
          !anchorElement ||
          (matchingMenuOptions.length === 0 && !loadMentions)
        ) {
          return null;
        }

        const ListComponent =
          listType === "container-pinned"
            ? ContainerPinnedMentionsList
            : CaretPinnedMentionsList;

        return createPortal(
          <ListComponent>
            {matchingMenuOptions.map((option, index) => (
              <MentionMenuItem
                key={option.id}
                option={option}
                isSelected={index === selectedIndex}
                onClick={() => {
                  setHighlightedIndex(index);
                  selectOptionAndCleanUp(option);
                }}
                onMouseEnter={() => {
                  setHighlightedIndex(index);
                }}
              />
            ))}
            {loadMentions && hasMore && !isLoading && (
              <div ref={moreMentionsLoadingRef} />
            )}
            {isLoading && <LoadingSpinner />}
          </ListComponent>,
          anchorElement
        );
      }}
    />
  );
};
