import { cx, isoToLocalDate, useTranslations } from "@jugl-web/utils";
import React from "react";
import format from "date-fns/format";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { Highlightify } from "@jugl-web/utils/utils/Highlightify";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as CalendarGreyIcon } from "./assets/calendar-grey.svg";

export const SubTask: React.FC<{
  isCompleted: boolean;
  completedAt: string | null;
  name: string;
  isLast: boolean;
  dueAt: string | null;
  searchQuery?: string;
}> = ({ isCompleted, isLast, name, completedAt, dueAt, searchQuery }) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  return (
    <div className="flex gap-2.5">
      <div className="flex flex-col items-center">
        <div
          className={cx(
            "flex h-6 w-6 shrink-0 items-center justify-center rounded-full",
            isCompleted ? "bg-primary text-white" : "bg-grey-200 text-grey-800"
          )}
        >
          {isCompleted && <CheckIcon />}
        </div>
        {!isLast && <div className="bg-dark-200 h-full w-px" />}
      </div>
      <div className={cx("flex min-w-0 flex-col", !isLast && "mb-10")}>
        <div className="font-secondary whitespace-pre-wrap break-words text-sm leading-[21px] text-black">
          <Linkify>
            <Mentionify>
              <Highlightify
                highlightClassName="text-primary bg-transparent text-sm font-secondary"
                searchWord={searchQuery || ""}
              >
                {name}
              </Highlightify>
            </Mentionify>
          </Linkify>
        </div>
        {!isCompleted && dueAt && (
          <div className="text-dark-500 flex items-center text-xs leading-[17px] tracking-[0.12px]">
            <CalendarGreyIcon />
            <div className="pt-0.5">
              {t(
                {
                  id: "guest-task-page.due-to-date",
                  defaultMessage: "Due to: {date}",
                },
                {
                  date: format(isoToLocalDate(dueAt), "MMMM d, hh:mm a", {
                    locale: dateLocale,
                  }),
                }
              )}
            </div>
          </div>
        )}

        {isCompleted && completedAt && (
          <div className="text-dark-500 flex items-center text-xs leading-[17px] tracking-[0.12px]">
            <CalendarIcon />
            <div className="pt-0.5">
              {t(
                {
                  id: "guest-task-page.sub-task-completion-date",
                  defaultMessage: "Completed on: {date}",
                },
                {
                  date: format(isoToLocalDate(completedAt), "MMMM d, hh:mm a", {
                    locale: dateLocale,
                  }),
                }
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
