import { useTranslations, useAppVariant, cx } from "@jugl-web/utils";
import { InvoiceGuestLog, useRestApiProvider } from "@jugl-web/rest-api";
import { useEffect, useState, useCallback, Fragment } from "react";
import { useEffectOnce } from "react-use";
import { InvoiceGuestLogDto } from "@jugl-web/rest-api/orders/models/InvoiceGuestLog";
import { OrderResponseDto } from "@jugl-web/rest-api/orders/models/OrderResponse";
import { useInView } from "react-intersection-observer";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DEFAULT_ENTITY_CURRENCY } from "@jugl-web/utils/consts";
import { ReactComponent as ClockIcon } from "./assets/clock.svg";
import { InvoiceHistoryItem } from "./components/InvoiceHistoryItem";

export const TaskInvoiceHistoryTab = ({
  entityId,
  invoiceId,
  orderResponse,
}: {
  entityId: string;
  invoiceId: string;
  orderResponse: OrderResponseDto;
}) => {
  const { t } = useTranslations();
  const { ref: isPaginationAnchorInView, inView } = useInView();
  const { ordersApi } = useRestApiProvider();
  const { data: initData } = ordersApi.useGuestInitQuery(
    orderResponse?.invoice?.entity_id
      ? { entityId: orderResponse.invoice.entity_id }
      : skipToken
  );
  const [loadInvoiceLogsData] = ordersApi.useLazyGetOrderInvoiceHistoryQuery();
  const { isMobile } = useAppVariant();

  const [data, setData] = useState<{
    items: InvoiceGuestLog[];
    page: number;
    isInitialized: boolean;
    hasMore: boolean;
  }>({
    items: [],
    page: 0,
    isInitialized: false,
    hasMore: true,
  });
  const [internalIsLoading, setInternalIsLoading] = useState(false);

  const handleLoadMoreData = useCallback(
    async (shouldReset?: boolean) => {
      if (!shouldReset && (!data.hasMore || internalIsLoading)) {
        return;
      }

      setInternalIsLoading(true);
      const response = await loadInvoiceLogsData({
        page: shouldReset ? 1 : data.page + 1,
        page_size: 20,
        invoiceId,
        entityId,
      });

      if (response.data && response.data) {
        const hasMore = response.data.total_pages > response.data.page_number;
        const items = () => {
          if (shouldReset) return response.data?.data || [];
          if (!response.data) return data.items;
          const uniqueItems = new Map(
            [...data.items, ...response.data.data].map((item) => [
              `${item.inserted_at}-${item.action}-${item.info.item_name}-${item.info.new_price}-${item.info.new_qty}`,
              item,
            ])
          );
          return Array.from(uniqueItems.values());
        };
        setData((prev) => ({
          ...prev,
          items: items(),
          page: response.data?.page_number || 1,
          isInitialized: true,
          hasMore,
        }));
      }
      setInternalIsLoading(false);
    },
    [data, entityId, invoiceId, loadInvoiceLogsData, internalIsLoading]
  );

  const getItemUnits = (log: InvoiceGuestLogDto) => {
    const invoiceItem = orderResponse.invoice?.items.find(
      (item) => item.name === log.info.item_name
    );
    if (log.action === "line_item_updated") {
      return invoiceItem?.unit || "pcs";
    }
    return "pcs";
  };

  useEffectOnce(() => {
    handleLoadMoreData(true);
  });

  useEffect(() => {
    if (inView && data.hasMore) {
      handleLoadMoreData();
    }
  }, [inView, handleLoadMoreData, data.hasMore]);

  return (
    <div className="flex flex-col">
      {isMobile && (
        <div className="flex gap-2 px-4 text-sm">
          <ClockIcon />
          {t({
            id: "common.history",
            defaultMessage: "History",
          })}
        </div>
      )}
      {data.items.map((log, index) => (
        <Fragment key={log.inserted_at}>
          <div
            className={cx("px-8", {
              "px-4": isMobile,
            })}
          >
            <InvoiceHistoryItem
              log={log}
              currency={initData?.entity.currency || DEFAULT_ENTITY_CURRENCY}
              units={getItemUnits(log)}
            />
          </div>
          {index !== data.items.length - 1 && (
            <div className="border-grey-200 border-b" />
          )}
        </Fragment>
      ))}
      {data.hasMore && <div ref={isPaginationAnchorInView} />}
      {data.isInitialized && data.items.length === 0 && (
        <span className="p-4 text-sm text-[#828282]">
          {t({
            id: "guest-task-page.no-history-yet",
            defaultMessage: "No History yet",
          })}
        </span>
      )}
    </div>
  );
};
