import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { endOfDay, format } from "date-fns";
import { FC, FocusEvent, useCallback, useEffect, useState } from "react";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";

const isPM = (date: Date) => date.getHours() >= 12;

const convert12HourTimeStringToDate = (timeString: string, baseDate: Date) => {
  const timeParts = timeString.split(":").map(Number);

  let hours = timeParts[0];
  const minutes = timeParts[1] ?? 0;

  const isBaseDatePM = isPM(baseDate);

  if (isBaseDatePM && hours < 12) {
    hours += 12;
  } else if (!isBaseDatePM && hours === 12) {
    hours = 0;
  }

  const newDate = new Date(baseDate);
  newDate.setHours(hours, minutes, 0, 0);

  return newDate;
};

interface TimeInputProps {
  selectedDate: Date | null;
  onDateChange: (date: Date) => void;
}

export const TimeInput: FC<TimeInputProps> = ({
  selectedDate,
  onDateChange,
}) => {
  const [value, setValue] = useState("12:00");
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslations();

  const isInputDisabled = !selectedDate;
  const isDatePM = selectedDate ? isPM(selectedDate) : false;
  const isEOD = selectedDate
    ? format(selectedDate, "HH:mm") === "23:59"
    : false;

  const resetInputValue = useCallback(() => {
    if (selectedDate) {
      setValue(format(selectedDate, "hh:mm"));
    }
  }, [selectedDate]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    assert(!!selectedDate, "Selected date must be defined");

    try {
      if (event.target.validity.valid) {
        const newDate = convert12HourTimeStringToDate(value, selectedDate);
        onDateChange(newDate);
      } else {
        throw new Error("Invalid time format");
      }
    } catch {
      resetInputValue();
    } finally {
      setIsEditing(false);
    }
  };

  const handleResetTime = () => {
    assert(!!selectedDate, "Selected date must be defined");

    onDateChange(endOfDay(selectedDate));
  };

  const getDateWithOppositeMeridiem = useCallback(() => {
    assert(!!selectedDate, "Selected date must be defined");

    const newDate = new Date(selectedDate);
    newDate.setHours(selectedDate.getHours() + (isDatePM ? -12 : 12));

    return newDate;
  }, [isDatePM, selectedDate]);

  const handleToggleMeridiem = () => {
    onDateChange(getDateWithOppositeMeridiem());
  };

  useEffect(() => {
    resetInputValue();
  }, [selectedDate, resetInputValue]);

  return (
    <InteractiveContainer
      isDisabled={isInputDisabled}
      className={cx(
        "flex h-10 w-full items-center justify-between gap-1 rounded-md px-4 outline outline-1 outline-transparent transition-all",
        isInputDisabled
          ? "bg-grey-200 text-dark-600 cursor-not-allowed"
          : "bg-primary-50 text-dark-800 hover:outline-primary",
        isEditing && "outline-primary"
      )}
      onClick={() => setIsEditing(true)}
    >
      <div className="flex items-center gap-1">
        <span>{t({ id: "tasks-page.time", defaultMessage: "Time" })}:</span>
        {isEditing ? (
          <input
            autoFocus
            type="text"
            className="text-dark-800 font-primary placeholder:text-grey-700 m-0 w-[45px] border-none bg-transparent p-0 text-base outline-none"
            pattern="^(0?[1-9]|1[0-2])(:[0-5]?[0-9])?$"
            placeholder="09:00"
            required
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onBlur={handleBlur}
          />
        ) : (
          <span className="w-[40px]">
            {selectedDate ? format(selectedDate, "hh:mm") : "--"}
          </span>
        )}
        {selectedDate && (
          <button
            type="button"
            disabled={isInputDisabled}
            className="text-dark-800 font-primary disabled:text-dark-600 cursor-pointer border-none bg-transparent p-0 text-base outline-none disabled:cursor-not-allowed"
            onClick={(event) => {
              event.stopPropagation();
              handleToggleMeridiem();
            }}
          >
            {format(selectedDate, "a")}
          </button>
        )}
      </div>
      {selectedDate && !isEOD && (
        <PlainButton
          onClick={(event) => {
            event.stopPropagation();
            handleResetTime();
          }}
        >
          <RemoveIcon />
        </PlainButton>
      )}
    </InteractiveContainer>
  );
};
