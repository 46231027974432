import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";
import Highlighter from "react-highlight-words";
import { InteractiveContainer } from "../../../InteractiveContainer";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";

export interface ListBoxItemComponentProps {
  label: ReactNode;
  isSelected: boolean;
  isInteractive: boolean;
  height: number;
  startSlot?: ReactNode;
  endSlot?: ReactNode;
  secondaryText?: ReactNode;
  highlightedText?: string;
  metaAttributes?: Record<string, string>;
  onClick: () => void;
}

export const ListBoxItemComponent: FC<ListBoxItemComponentProps> = ({
  label,
  isSelected,
  isInteractive,
  height,
  startSlot,
  endSlot,
  secondaryText,
  highlightedText,
  metaAttributes,
  onClick,
}) => (
  <InteractiveContainer
    className={cx(
      "relative flex shrink-0 items-center gap-3 rounded-lg px-3 transition-colors",
      isSelected ? "bg-primary-50" : "hover:bg-grey-100",
      !isInteractive && "cursor-default"
    )}
    style={{ height }}
    onClick={isInteractive ? onClick : undefined}
    {...metaAttributes}
  >
    {startSlot && <span className="shrink-0 leading-[0]">{startSlot}</span>}
    <div className="flex grow flex-col gap-1.5 overflow-hidden">
      <span className="text-dark truncate">
        {typeof label === "string" && highlightedText ? (
          <Highlighter
            autoEscape
            highlightClassName="text-primary font-medium"
            highlightTag="span"
            textToHighlight={label}
            searchWords={[highlightedText]}
          />
        ) : (
          label
        )}
      </span>
      {secondaryText && (
        <span className="text-grey-700 truncate text-xs tracking-[0.12px]">
          {secondaryText}
        </span>
      )}
    </div>
    {endSlot || (
      <CheckIcon
        className={cx(
          "shrink-0 transition-opacity",
          isSelected ? "opacity-100" : "opacity-0"
        )}
      />
    )}
  </InteractiveContainer>
);
