import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes } from "react";

export const HeaderBreadcrumbsItemContent = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ children, className, ...props }, ref) => (
  <span
    ref={ref}
    className={cx("inline-flex items-center gap-2.5", className)}
    {...props}
  >
    {children}
  </span>
));
