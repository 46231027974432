import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import {
  Menu as HeadlessUIMenu,
  MenuItem as HeadlessUIMenuItem,
  MenuButton as HeadlessUIMenuButton,
  MenuItems as HeadlessUIMenuItems,
  Portal,
  Transition,
} from "@headlessui/react";
import { cx, getUniqueId } from "@jugl-web/utils";
import {
  CSSProperties,
  Fragment,
  MouseEvent,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkbox } from "../Checkbox";
import { PlainButton } from "../PlainButton";
import { Radio } from "../Radio";
import { Switch } from "../Switch";
import { ReactComponent as MoreArrow } from "./assets/more-arrow.svg";

interface RenderTriggerProps {
  Trigger: typeof HeadlessUIMenuButton;
  triggerRef: Ref<HTMLButtonElement>;
  isOpen: boolean;
}

export type MenuItem<TValue = unknown> = {
  id: string;
  label: string;
  value?: TValue;
  icon?: JSX.Element;
  isHidden?: boolean;
  isSelected?: boolean;
  toggle?: "checkbox" | "switch" | "radio";
  endSlot?: ReactNode;
  labelClassName?: string;
  onSelect?: (event: MouseEvent<HTMLButtonElement>, close: () => void) => void;
  submenu?: Pick<
    MenuProps,
    "placement" | "onSelect" | "selectedId" | "sections"
  >;
};

export type MenuSections<TValue = unknown> = MenuItem<TValue>[][];

export interface MenuState {
  isOpen: boolean;
  onClose: () => void;
}

export type MenuProps<TValue = unknown> = {
  placement: Placement;
  sections: MenuSections<TValue>;
  id?: string;
  selectedId?: string;
  adjustToTriggerWidth?: boolean;
  className?: string;
  hasBackdrop?: boolean;
  backdropStyle?: "blur" | "transparent";
  autoClose?: boolean;
  /**
   * Automatically update menu position on window resize/scroll
   * @warning There's are a lot of issues with z-indices (for top bar, dialogs, etc.)
   * so it has to be used with caution
   */
  autoPosition?: boolean;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
  onSelect?: (
    item: MenuItem<TValue>,
    event: MouseEvent<HTMLButtonElement>,
    close: () => void
  ) => void;
  onStateChange?: (state: MenuState, menuId: string) => void;
};

export type MenuConfig<TValue = unknown> = Pick<
  MenuProps<TValue>,
  "sections" | "onSelect"
>;

export function Menu<TValue>({
  placement,
  sections,
  id,
  selectedId,
  adjustToTriggerWidth,
  className,
  hasBackdrop,
  backdropStyle,
  autoClose,
  autoPosition,
  renderTrigger,
  onSelect,
  onStateChange,
}: MenuProps<TValue>) {
  const [state, setState] = useState<MenuState>({
    isOpen: false,
    onClose: () => {},
  });

  const [subMenuOpenState, setSubMenuOpenState] = useState<
    Record<string, boolean>
  >({});

  const menuId = useMemo(() => id || getUniqueId(), [id]);

  const { refs, floatingStyles, elements } = useFloating<HTMLButtonElement>({
    placement,
    middleware: [flip(), shift(), offset({ mainAxis: 4 })],
    whileElementsMounted: autoPosition ? autoUpdate : undefined,
  });

  const menuWidth = useMemo<CSSProperties["width"]>(() => {
    if (!adjustToTriggerWidth || !elements.reference) {
      return undefined;
    }

    return elements.reference.getBoundingClientRect().width;
  }, [adjustToTriggerWidth, elements.reference]);

  const isMenuItemsStatic = useMemo(
    () => Object.values(subMenuOpenState).includes(true),
    [subMenuOpenState]
  );

  const subMenuOpenStateChangeHandler = useCallback(
    (subMenuState: MenuState, subMenuId: string) => {
      setSubMenuOpenState((prev) => ({
        ...prev,
        [subMenuId]: subMenuState.isOpen,
      }));
    },
    []
  );

  useEffect(
    () =>
      onStateChange?.(
        { isOpen: state.isOpen || isMenuItemsStatic, onClose: state.onClose },
        menuId
      ),
    [state, onStateChange, isMenuItemsStatic, menuId]
  );

  return (
    <HeadlessUIMenu as="div">
      {({ open, close }) => {
        if (open !== state.isOpen) {
          setState({ isOpen: open, onClose: close });
        }

        return (
          <>
            {renderTrigger({
              Trigger: HeadlessUIMenu.Button,
              triggerRef: refs.setReference,
              isOpen: open,
            })}
            {hasBackdrop && (
              <Transition
                as={Fragment}
                enter={cx("transition-opacity duration-200")}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={cx("transition-opacity duration-200")}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={cx("fixed inset-0 z-50", {
                    "backdrop-blur-[1px]": backdropStyle === "blur",
                  })}
                  style={{
                    backgroundColor:
                      backdropStyle === "blur"
                        ? "rgba(14, 14, 14, 0.66)"
                        : "transparent",
                  }}
                />
              </Transition>
            )}
            <Portal>
              <HeadlessUIMenuItems
                static={isMenuItemsStatic}
                as="div"
                ref={refs.setFloating}
                className={cx(
                  "z-50 max-h-[300px] min-w-[200px] overflow-y-auto rounded-xl bg-white outline-none",
                  className
                )}
                style={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.12)",
                  width: menuWidth,
                  ...floatingStyles,
                }}
              >
                {sections.map((section, index) => {
                  const hasNextSection = !!sections[index + 1];

                  return (
                    <Fragment key={+index}>
                      {section.map((item) => {
                        if (item.isHidden) {
                          return null;
                        }
                        if (item.submenu) {
                          return (
                            <Menu
                              key={item.id}
                              placement={item.submenu.placement}
                              sections={item.submenu.sections}
                              onStateChange={subMenuOpenStateChangeHandler}
                              id={item.id}
                              renderTrigger={({ Trigger, triggerRef }) => (
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className={cx(
                                    "flex items-center transition hover:bg-gray-50",
                                    {
                                      "bg-grey-100": subMenuOpenState[item.id],
                                    }
                                  )}
                                >
                                  <Trigger
                                    as={PlainButton}
                                    ref={triggerRef}
                                    className="flex h-full w-full items-center gap-2.5 px-4 py-3"
                                  >
                                    {item.icon && (
                                      <span className="shrink-0">
                                        {item.icon}
                                      </span>
                                    )}
                                    <span
                                      className={cx(
                                        "text-dark grow text-left text-sm leading-[16px]",
                                        item.labelClassName
                                      )}
                                    >
                                      {item.label}
                                    </span>
                                    <MoreArrow />
                                  </Trigger>
                                </div>
                              )}
                            />
                          );
                        }

                        return (
                          <HeadlessUIMenuItem key={item.id}>
                            {({ active }) => {
                              const isSelected =
                                (selectedId &&
                                  item.id &&
                                  selectedId === item.id) ||
                                !!item.isSelected;

                              return (
                                <div
                                  className={cx(
                                    "flex items-center transition",
                                    {
                                      "bg-grey-100": active,
                                      "bg-grey-200 font-semibold":
                                        isSelected && !item.toggle,
                                    }
                                  )}
                                >
                                  <PlainButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      if (item.toggle) {
                                        event.preventDefault();
                                      }
                                      if (autoClose) {
                                        close();
                                      }
                                      onSelect?.(item, event, close);
                                      item.onSelect?.(event, close);
                                    }}
                                    className="flex h-full w-full items-center gap-2.5 px-4 py-3"
                                  >
                                    {item.icon && (
                                      <span className="shrink-0">
                                        {item.icon}
                                      </span>
                                    )}
                                    <span
                                      className={cx(
                                        "text-dark grow text-left text-sm leading-[16px]",
                                        item.labelClassName
                                      )}
                                    >
                                      {item.label}
                                    </span>
                                    {item.toggle === "checkbox" && (
                                      <Checkbox
                                        readOnly
                                        isChecked={isSelected}
                                        // Fixed strange hack with re-render
                                        key={String(isSelected)}
                                        className="m-0"
                                      />
                                    )}
                                    {item.toggle === "radio" && (
                                      <Radio
                                        readOnly
                                        isChecked={isSelected}
                                        // Fixed strange hack with re-render
                                        key={String(isSelected)}
                                        className="m-0"
                                      />
                                    )}
                                    {item.toggle === "switch" && (
                                      <Switch
                                        as="div"
                                        isChecked={isSelected}
                                        // Fixed strange hack with re-render
                                        key={String(isSelected)}
                                        className="m-0"
                                      />
                                    )}
                                    {item.endSlot}
                                  </PlainButton>
                                </div>
                              );
                            }}
                          </HeadlessUIMenuItem>
                        );
                      })}
                      {hasNextSection && (
                        <div key={+index} className="bg-grey-200 h-px" />
                      )}
                    </Fragment>
                  );
                })}
              </HeadlessUIMenuItems>
            </Portal>
          </>
        );
      }}
    </HeadlessUIMenu>
  );
}
