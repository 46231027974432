import React, { useEffect, useMemo, useRef } from "react";
import { isAPIError, useAppVariant } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useMatch } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingAnimation } from "@jugl-web/ui-components";
import { useAuthProvider } from "@web-guest-src/modules/auth/providers/AuthProvider";
import { PageWrapper } from "../../../common/components/PageWrapper";
import { TaskExpiredContent } from "./components/TaskExpiredContent";
import { MobileGuestTaskContent } from "./components/MobileGuestTaskContent/MobileGuestTaskContent";
import { WebGuestTaskContent } from "./components/WebGuestTaskContent";

export const GuestTaskPage: React.FC = () => {
  const { tasksApi, ordersApi } = useRestApiProvider();
  const { variant } = useAppVariant();

  const { meId } = useAuthProvider();
  const match = useMatch("/guest/task/:taskShortId");

  const {
    data: taskData,
    isLoading,
    isError,
    error,
  } = tasksApi.useGetGuestTaskQuery(
    match?.params?.taskShortId
      ? { taskShortId: match.params.taskShortId }
      : skipToken
  );

  const { data: orderResponse } = ordersApi.useGetGuestOrderResponseQuery(
    taskData?.task.order_resp
      ? {
          entityId: taskData.entity.id,
          orderResponseId: taskData.task.order_resp,
        }
      : skipToken
  );

  const [sendTaskOpenedActivity] =
    tasksApi.useSendGuestTaskOpenedActivityMutation();
  const openedActivitySent = useRef(false);

  useEffect(() => {
    if (
      !taskData ||
      openedActivitySent.current ||
      !!taskData.activity.find(
        (item) =>
          item.action_details.action === "guest_opened" &&
          item.action_by.user_id === meId
      )
    ) {
      return;
    }
    openedActivitySent.current = true;
    sendTaskOpenedActivity({
      taskId: taskData.task.id,
      entityId: taskData.entity.id,
    });
  }, [meId, sendTaskOpenedActivity, taskData]);

  const task = taskData?.task;
  const isLinkExpired = useMemo(() => {
    if (error && isAPIError(error) && error.status === 400) {
      return true;
    }
    return false;
  }, [error]);

  if (isLinkExpired) {
    return <TaskExpiredContent />;
  }

  if (isLoading || isError || !task) {
    return (
      <PageWrapper>
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <div className="px-8 text-center text-sm text-gray-500">
              Error loading task, please try again later or contact support
            </div>
          )}
        </div>
      </PageWrapper>
    );
  }

  if (variant === "mobile") {
    return (
      <MobileGuestTaskContent
        taskData={taskData}
        orderResponse={orderResponse}
      />
    );
  }
  return (
    <WebGuestTaskContent taskData={taskData} orderResponse={orderResponse} />
  );
};
