import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  AttachmentsLayout,
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { DetailedTask, TaskAttachment } from "@jugl-web/rest-api/tasks";
import {
  useAppVariant,
  useToast,
  useTranslations,
  useSearchInput,
  cx,
} from "@jugl-web/utils";
import React, { useState, useRef } from "react";
import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { TabContentButton } from "@jugl-web/domain-resources/tasks/components/TabContentButton";
import { TabContentFeedbackText } from "@jugl-web/domain-resources/tasks/components/TabContentFeedbackText";
import { ReactComponent as GridIcon } from "./assets/grid.svg";
import { ReactComponent as ListIcon } from "./assets/list.svg";
import { ReactComponent as AttachmentsIcon } from "./assets/attachments.svg";

export const TaskAttachmentsSection: React.FC<{
  task: DetailedTask;
}> = ({ task }) => {
  const { t } = useTranslations();
  const { previewFile } = useFilePreview();

  const { downloadFile } = useDownloadFile();
  const { variant, isMobile } = useAppVariant();
  const { toast, closeToast } = useToast({ variant });

  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const [layout, setLayout] = useState<AttachmentsLayout>("grid");

  const {
    searchQuery,
    inputProps: searchInputProps,
    reset: resetSearchInput,
  } = useSearchInput();

  const handleDownloadAttachment = async (attachment: TaskAttachment) => {
    const downloadingToastId = toast(
      t({ id: "feedback.file-downloading", defaultMessage: "Downloading..." }),
      { variant: "info", persist: true }
    );

    const response = await downloadFile({
      isGuestApp: true,
      entityId: task.entity_id,
      id: attachment.id,
      fileName: attachment.name,
      fileSize: attachment.size,
      mimeType: attachment.content_type,
    });

    if ("data" in response) {
      downloadBlobAsFile(response.data, attachment.name);
    } else {
      toast(
        t(
          {
            id: "feedback.attachment-download-failed",
            defaultMessage: "Couldn't download file {fileName}",
          },
          { fileName: attachment.name }
        ),
        { variant: "error", style: { zIndex: 99999 } }
      );
    }

    closeToast(downloadingToastId);
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) => {
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
      onDownload: () => handleDownloadAttachment(attachment),
    });
  };

  return (
    <>
      {isMobile && (
        <div className="flex items-center justify-between pb-[22px]">
          <div className="flex items-center gap-1 text-sm">
            <AttachmentsIcon />
            {t({
              id: "common.attachments",
              defaultMessage: "Attachments",
            })}
          </div>
          <TabContentButton
            label=""
            icon={layout === "grid" ? <ListIcon /> : <GridIcon />}
            onClick={() => setLayout(layout === "grid" ? "list" : "grid")}
            className="flex h-[32px] w-[32px] justify-center"
          />
        </div>
      )}
      <div className="flex items-center justify-between gap-4">
        <SearchInput
          variant="filled"
          color="grey"
          containerClassName={cx("w-full", {
            "max-w-[320px]": !isMobile,
          })}
          onClear={resetSearchInput}
          {...searchInputProps}
        />
        {!isMobile && (
          <div className="flex items-center gap-4">
            <TabContentButton
              label={
                layout === "grid"
                  ? t({
                      id: "tasks-page.show-as-list",
                      defaultMessage: "Show as list",
                    })
                  : t({
                      id: "tasks-page.show-as-cards",
                      defaultMessage: "Show as cards",
                    })
              }
              icon={layout === "grid" ? <ListIcon /> : <GridIcon />}
              onClick={() => setLayout(layout === "grid" ? "list" : "grid")}
            />
          </div>
        )}
      </div>
      <div className="mt-8">
        <TaskAttachments
          mode="uncontrolled"
          ref={$taskAttachmentsRef}
          entityId={task.entity_id}
          layout={layout}
          emptyContent={
            <TabContentFeedbackText
              className="mt-8 text-sm"
              text={t({
                id: "tasks-page.no-attachments-added",
                defaultMessage: "No attachments added yet",
              })}
            />
          }
          noSearchResultsContent={
            <TabContentFeedbackText
              className="mt-8"
              text={t({
                id: "tasks-page.no-results",
                defaultMessage: "No results 😔",
              })}
            />
          }
          taskId={task.id}
          attachments={task.attachments}
          searchQuery={searchQuery}
          isEditable={false}
          onPreviewAttachment={handlePreviewAttachment}
          hideUpload
        />
      </div>
    </>
  );
};
