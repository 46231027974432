import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes, ReactNode } from "react";

interface HeaderBreadcrumbsItemContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isBold?: boolean;
  className?: string;
  onClick?: () => void;
}

export const HeaderBreadcrumbsItemContainer = forwardRef<
  HTMLDivElement,
  HeaderBreadcrumbsItemContainerProps
>(({ children, isBold, className, onClick, ...props }, ref) => (
  <div
    ref={ref}
    className={cx(
      "text-dark font-secondary text-lg font-normal leading-5",
      !!onClick && "cursor-pointer",
      isBold && "font-medium",
      className
    )}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
));
