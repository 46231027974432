import { TaskActivity } from "@jugl-web/rest-api/tasks";
import React, { useCallback } from "react";
import {
  Menu,
  parseActivityType,
  PlainButton,
  TaskActivityItem,
} from "@jugl-web/ui-components";
import {
  isoToLocalDate,
  useAppVariant,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useTaskActivityParser } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivityParser";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useAuthProvider } from "@web-guest-src/modules/auth/providers/AuthProvider";
import { ReactComponent as OptionsVerticalIcon } from "./assets/options-vertical.svg";

export const CommentItem: React.FC<{
  activity: TaskActivity;
  entityId: string;
  taskId: string;
  isTaskCompleted: boolean;
  searchQuery?: string;
  handleEditComment?: () => void;
}> = ({
  activity,
  entityId,
  taskId,
  isTaskCompleted,
  searchQuery,
  handleEditComment,
}) => {
  const { parseTaskActivity } = useTaskActivityParser({
    meUser: undefined,
    entityId: undefined,
  });
  const { variant } = useAppVariant();
  const { meId } = useAuthProvider();
  const { toast } = useToast({ variant });
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const [deleteGuestTaskComment] = tasksApi.useDeleteGuestTaskCommentMutation();

  const canManageComment =
    !isTaskCompleted &&
    activity.action_details.action === "guest_comment" &&
    activity.action_by.user_id === meId;

  const handleDeleteComment = useCallback(async () => {
    if (!taskId || !entityId) return;
    const response = await deleteGuestTaskComment({
      taskId,
      entityId,
      commentId: activity.id,
    });
    if ("error" in response) {
      toast(
        t({
          id: "guest-task-page.message-delete-failed-try-again",
          defaultMessage: "Message deletion failed. Please try again",
        }),
        { variant: "error" }
      );
    }
  }, [activity, deleteGuestTaskComment, taskId, entityId, t, toast]);

  const parsedTaskActivity = parseTaskActivity(activity);
  if (!parsedTaskActivity) {
    return null;
  }
  return (
    <div className="border-grey-200 border-b last:border-none">
      <TaskActivityItem
        isGuestApp
        isMyActivity={parsedTaskActivity.raw.action_by.user_id === meId}
        type={parseActivityType(parsedTaskActivity.raw.action_details.action)}
        action={parsedTaskActivity.raw.action_details.action}
        variant={variant}
        username={parsedTaskActivity.user.username}
        userImageUrl={parsedTaskActivity.user.avatarUrl}
        message={parsedTaskActivity.message}
        date={isoToLocalDate(parsedTaskActivity.raw.inserted_at)}
        ratingRate={parsedTaskActivity.rating?.rate}
        ratingTags={parsedTaskActivity.rating?.tags}
        className={variant === "mobile" ? "px-4 py-6" : undefined}
        searchQuery={searchQuery}
        contextMenuSlot={
          canManageComment && (
            <Menu
              placement="bottom-end"
              sections={[
                [
                  {
                    id: "edit",
                    label: t({
                      id: "common.edit",
                      defaultMessage: "Edit",
                    }),
                    onSelect: (_, close) => {
                      handleEditComment?.();
                      close();
                    },
                  },
                  {
                    id: "delete",
                    label: t({
                      id: "common.delete",
                      defaultMessage: "Delete",
                    }),
                    onSelect: (_, close) => {
                      handleDeleteComment();
                      close();
                    },
                  },
                ],
              ]}
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger as={PlainButton} ref={triggerRef}>
                  <OptionsVerticalIcon />
                </Trigger>
              )}
            />
          )
        }
      />
    </div>
  );
};
