import { MobileDrawer } from "@jugl-web/ui-components";
import { TaskActivity, TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { useState, useRef } from "react";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { useTranslations, cx } from "@jugl-web/utils";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { TaskCommentsInput } from "../TaskCommentsInput";
import { TaskCommentsSection } from "../TaskCommentsSection";
import { ReactComponent as CommentsIcon } from "./assets/comments.svg";
import { TaskFeedbackSection } from "../TaskFeedbackSection";

export const MobileCommentsDrawer: React.FC<{
  isOpen: boolean;
  taskData: GuestTaskDataModel;
  onClose: () => void;
}> = ({ isOpen, taskData, onClose }) => {
  const { task } = taskData;

  const { t } = useTranslations();
  const $inputContainerRef = useRef<HTMLDivElement>(null);

  const [editedComment, setEditedComment] = useState<TaskActivity>();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const isTaskCompleted = TaskDefaultStatusId.completed === task.status;

  return (
    <MobileDrawer
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      placement="bottom"
      header={{
        startSlot: <CommentsIcon />,
        title: t({
          id: "common.comments",
          defaultMessage: "Comments",
        }),
        hasCloseButton: false,
        showOnlyEndSlot: isInputExpanded,
        customEndSlot: (
          <div className={cx({ "w-full": isInputExpanded })}>
            <ExpandableSearchBar
              onSearch={(value) => setSearchQuery(value)}
              defaultValue={searchQuery}
              color="grey"
              expandedClassName="w-full"
              size="md"
              onExpand={(isExpanded) => {
                setIsInputExpanded(isExpanded);
              }}
              className="w-full"
            />
          </div>
        ),
      }}
      hasBackdrop
    >
      <MobileDrawer.Content className="px-0">
        <TaskCommentsSection
          taskData={taskData}
          handleEditComment={setEditedComment}
          searchQuery={searchQuery}
        />
      </MobileDrawer.Content>
      <MobileDrawer.Actions className="px-0">
        <div className="px-4">
          {isTaskCompleted && !taskData.hasGuestFeedback && (
            <TaskFeedbackSection taskData={taskData} />
          )}
        </div>
        {!isTaskCompleted && (
          <div>
            <div className="bg-dark-100 h-[1px] w-full" />
            <div className="px-4" ref={$inputContainerRef}>
              <TaskCommentsInput
                entityId={task.entity_id}
                taskId={task.id}
                editedComment={editedComment}
                cancelEditComment={() => setEditedComment(undefined)}
              />
            </div>
          </div>
        )}
      </MobileDrawer.Actions>
    </MobileDrawer>
  );
};
