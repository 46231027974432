/* eslint-disable @typescript-eslint/lines-between-class-members, no-cond-assign */
import { TFunction } from "@jugl-web/utils";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { $isListNode, ListItemNode, ListNode } from "@lexical/list";
import { Transformer } from "@lexical/markdown";
import { InitialConfigType } from "@lexical/react/LexicalComposer";
import { MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $isAtNodeEnd } from "@lexical/selection";
import { $findMatchingParent, $getNearestNodeOfType } from "@lexical/utils";
import {
  $isRootOrShadowRoot,
  ElementNode,
  RangeSelection,
  SerializedEditorState,
  SerializedLexicalNode,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedRootNode,
  SerializedTabNode,
  SerializedTextNode,
  TextNode,
} from "lexical";
import { uniq } from "lodash";
import { ExtendedTextNode } from "./nodes/ExtendedTextNode";
import { MentionNode, SerializedMentionNode } from "./nodes/MentionNode";
import {
  $createVariableNodeBasedOnTextNode,
  $isVariableNode,
  VariableNode,
} from "./nodes/VariableNode";

export const getLexicalConfig = (
  namespace: string,
  isDisabled: boolean
): InitialConfigType => ({
  namespace,
  editable: !isDisabled,
  theme: {
    paragraph: "m-0",
    mention: "font-bold text-primary",
    variable: "text-primary-800",
    link: "text-primary-800",
    text: {
      bold: "font-bold",
      highlight: "bg-[#EFCDFF]",
      italic: "italic",
      strikethrough: "line-through",
      underline: "underline",
      underlineStrikethrough: "[text-decoration:underline_line-through]",
    },
  },
  nodes: [
    ExtendedTextNode,
    {
      replace: TextNode,
      with: (node) => new ExtendedTextNode(node.__text),
      withKlass: ExtendedTextNode,
    },
    MentionNode,
    VariableNode,
    ListItemNode,
    ListNode,
    LinkNode,
    AutoLinkNode,
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError: (error) => {
    // For debugging purposes
    // console.error("LEXICAL ERROR", error)
  },
});

export class MentionMenuOption extends MenuOption {
  id: string;
  name: string;
  imageUrl: string | null;

  constructor(id: string, name: string, imageUrl: string | null) {
    super(name);

    this.id = id;
    this.name = name;
    this.imageUrl = imageUrl;
  }

  matchesFilter(queryString: string) {
    return this.name.toLowerCase().includes(queryString.toLowerCase());
  }
}

// #region Lexical -> Jugl Format
const isSerializedParagraphNode = (
  node: SerializedLexicalNode
): node is SerializedParagraphNode => node.type === "paragraph";

const isSerializedTextNode = (
  node: SerializedLexicalNode
): node is SerializedTextNode =>
  node.type === "text" || node.type === ExtendedTextNode.getType();

const isSerializedLineBreakNode = (
  node: SerializedLexicalNode
): node is SerializedLineBreakNode => node.type === "linebreak";

const isSerializedTabNode = (
  node: SerializedLexicalNode
): node is SerializedTabNode => node.type === "tab";

const isSerializedMentionNode = (
  node: SerializedLexicalNode
): node is SerializedMentionNode => node.type === "mention";

// #endregion

// #region Jugl Format -> Lexical
const createSerializedRootNode = (): SerializedRootNode => ({
  children: [],
  direction: "ltr",
  format: "",
  indent: 0,
  type: "root",
  version: 1,
});

const createSerializedParagraphNode = (): SerializedParagraphNode => ({
  children: [],
  direction: "ltr",
  format: "",
  indent: 0,
  textFormat: 0,
  textStyle: "",
  type: "paragraph",
  version: 1,
});

export const createSerializedTextNode = (
  text: string,
  format = 0
): SerializedTextNode => ({
  detail: 0,
  format,
  mode: "normal",
  style: "",
  text,
  type: "text",
  version: 1,
});

const createSerializedLineBreakNode = (): SerializedLineBreakNode => ({
  type: "linebreak",
  version: 1,
});

const createSerializedTabNode = (): SerializedTabNode => ({
  detail: 2,
  format: 0,
  mode: "normal",
  style: "",
  text: "\t",
  type: "tab",
  version: 1,
});

const createSerializedMentionNode = (
  text: string,
  userId: string
): SerializedMentionNode => ({
  detail: 1,
  format: 0,
  mentionName: text,
  mode: "segmented",
  style: "",
  text,
  type: "mention",
  userId,
  version: 1,
});

export const toTextWithMentions = (
  serializedState: SerializedEditorState
): string => {
  if (serializedState.root.children.length !== 1) {
    throw new Error("Root element must have exactly one child");
  }

  const [maybeParagraphNode] = serializedState.root.children;

  if (!isSerializedParagraphNode(maybeParagraphNode)) {
    throw new Error("The only child of root element must be a paragraph node");
  }

  let output = "";

  maybeParagraphNode.children.forEach((node) => {
    if (isSerializedMentionNode(node)) {
      output += `@<${node.userId}>[${node.text}]`;
    } else if (isSerializedTextNode(node)) {
      output += node.text;
    } else if (isSerializedLineBreakNode(node)) {
      output += "\n";
    } else if (isSerializedTabNode(node)) {
      output += "\t";
    } else {
      throw new Error("Unknown node type");
    }
  });

  return output;
};

const joinRegExpsAsAlternatives = (...regexps: RegExp[]) => {
  const joinedPatterns = regexps.map((regexp) => regexp.source).join("|");
  const joinedFlags = uniq(regexps.map((regexp) => regexp.flags)).join("");

  return new RegExp(joinedPatterns, joinedFlags);
};

const MENTION_REGEX = /@<(.*?)>\[(.*?)\]/g;
const LINE_BREAK_REGEX = /\n/g;
const TAB_REGEX = /\t/g;
const MENTION_OR_LINE_BREAK_OR_TAB_REGEX = joinRegExpsAsAlternatives(
  MENTION_REGEX,
  LINE_BREAK_REGEX,
  TAB_REGEX
);

const transformTextWithMentionsToLexicalNodes = (
  text: string,
  t: TFunction
) => {
  const nodes: SerializedLexicalNode[] = [];

  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = MENTION_OR_LINE_BREAK_OR_TAB_REGEX.exec(text))) {
    const [matchedString] = match;

    if (matchedString.match(MENTION_REGEX)) {
      const [, userId, username] = match;

      if (lastIndex !== match.index) {
        nodes.push(
          createSerializedTextNode(text.substring(lastIndex, match.index))
        );
      }

      const mentionText =
        userId === MENTIONS_ALL_ID
          ? t({ id: "common.all", defaultMessage: "All" })
          : username;

      nodes.push(createSerializedMentionNode(mentionText, userId));
      lastIndex = match.index + matchedString.length;
    } else if (matchedString.match(LINE_BREAK_REGEX)) {
      if (lastIndex !== match.index) {
        nodes.push(
          createSerializedTextNode(text.substring(lastIndex, match.index))
        );
      }

      nodes.push(createSerializedLineBreakNode());
      lastIndex = match.index + matchedString.length;
    } else if (matchedString.match(TAB_REGEX)) {
      if (lastIndex !== match.index) {
        nodes.push(
          createSerializedTextNode(text.substring(lastIndex, match.index))
        );
      }

      nodes.push(createSerializedTabNode());
      lastIndex = match.index + matchedString.length;
    }
  }

  if (lastIndex < text.length) {
    nodes.push(createSerializedTextNode(text.substring(lastIndex)));
  }

  return nodes;
};

export const parseTextWithMentions = (
  text: string,
  t: TFunction
): SerializedEditorState => {
  const root = createSerializedRootNode();
  const paragraph = createSerializedParagraphNode();
  const nodes = transformTextWithMentionsToLexicalNodes(text, t);

  paragraph.children = nodes;
  root.children[0] = paragraph;

  return { root };
};
// #endregion

export const getListType = (selection: RangeSelection) => {
  const anchorNode = selection.anchor.getNode();

  let element =
    anchorNode.getKey() === "root"
      ? anchorNode
      : $findMatchingParent(anchorNode, (node) => {
          const parent = node.getParent();
          return parent !== null && $isRootOrShadowRoot(parent);
        });

  if (element === null) {
    element = anchorNode.getTopLevelElementOrThrow();
  }

  if ($isListNode(element)) {
    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
    return parentList ? parentList.getListType() : element.getListType();
  }

  return null;
};

export const getSelectedNode = (
  selection: RangeSelection
): TextNode | ElementNode => {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
};

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
      attributes: { rel: "noreferrer", target: "_blank" },
    };
  },
];

export const getWhatsappMarkdownTransformers = (
  supportedVariables: string[]
): Transformer[] => [
  // Italic (_)
  {
    format: ["italic"],
    intraword: false,
    tag: "_",
    type: "text-format",
  },
  // Bold (*)
  {
    format: ["bold"],
    tag: "*",
    type: "text-format",
  },
  // Strikethrough (~)
  {
    format: ["strikethrough"],
    tag: "~",
    type: "text-format",
  },
  // Variables ({{Variable Name}})
  {
    dependencies: [VariableNode],
    type: "text-match",
    importRegExp: /{{([^}]+)}}/,
    regExp: /{{([^}]+)}}$/,
    // Markdown -> Lexical nodes transformation
    replace: (textNode, match) => {
      const variableName = match[1];

      // Check if the variable is valid
      if (!supportedVariables.includes(variableName)) {
        return;
      }

      const variableNode = $createVariableNodeBasedOnTextNode(
        variableName,
        textNode
      );

      textNode.replace(variableNode);
    },
    // Lexical nodes -> Markdown transformation
    export: (node, _, exportFormat) => {
      if (!$isVariableNode(node)) {
        return null;
      }

      return exportFormat(node, `{{${node.__variableName}}}`);
    },
  },
];
