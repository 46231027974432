import { useTranslations } from "@jugl-web/utils";
import {
  TaskDetailsTabId,
  TabSearchPersistenceProvider,
} from "@jugl-web/domain-resources/tasks";
import { FC, useMemo, useState } from "react";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { OrderResponseDto } from "@jugl-web/rest-api/orders/models/OrderResponse";
import { Tab } from "@jugl-web/ui-components/web/TabsLayout/components/Tab";
import { LoadingSpinner } from "@jugl-web/ui-components";
import { TaskSubTasksSection } from "../TaskSubTasksSection";
import { TaskAttachmentsSection } from "../TaskAttachmentsSection";
import { TaskOrderDetailsTab } from "../TaskOrderDetailsTab";
import { TaskInvoiceHistoryTab } from "../TaskInvoiceHistoryTab";

interface TaskDetailsTab {
  id: TaskDetailsTabId;
  title: string;
  Component: FC;
  counter?: number;
  isHidden?: boolean;
}

export const TaskTabs: FC<{
  taskData: GuestTaskDataModel;
  orderResponse?: OrderResponseDto;
}> = ({ taskData, orderResponse }) => {
  const [selectedTab, setSelectedTab] = useState<TaskDetailsTabId>(
    taskData.task.order_resp ? "order-details" : "subtasks"
  );

  const { t } = useTranslations();

  const tabs = useMemo<TaskDetailsTab[]>(
    () => [
      {
        id: "order-details",
        title: t({
          id: "tasks-page.order-details",
          defaultMessage: "Order details",
        }),
        isHidden: !taskData.task.order_resp,
        counter: taskData.task.order_count,
        Component: () => <TaskOrderDetailsTab orderResponse={orderResponse} />,
      },
      {
        id: "subtasks",
        title: t({
          id: "tasks-page.subtasks",
          defaultMessage: "Subtasks",
        }),
        counter: taskData.task.checklist.length,
        Component: () => <TaskSubTasksSection task={taskData.task} />,
      },
      {
        id: "attachments",
        title: t({
          id: "tasks-page.attachments",
          defaultMessage: "Attachments",
        }),
        counter: taskData.task.attachment_count,
        Component: () => <TaskAttachmentsSection task={taskData.task} />,
      },
      {
        id: "history",
        title: t({
          id: "tasks-page.history",
          defaultMessage: "History",
        }),
        isHidden: !taskData.task.order_resp,
        counter: taskData.task.history_count,
        Component: () =>
          orderResponse?.invoice?.id ? (
            <TaskInvoiceHistoryTab
              entityId={taskData.entity.id}
              invoiceId={orderResponse?.invoice?.id}
              orderResponse={orderResponse}
            />
          ) : (
            <LoadingSpinner />
          ),
      },
    ],
    [orderResponse, t, taskData]
  );

  const activeTab = useMemo(
    () => tabs.find((tab) => tab.id === selectedTab) ?? tabs[0],
    [tabs, selectedTab]
  );

  return (
    <div className="mt-12">
      <div
        className="border-b-grey-300 flex items-center overflow-x-auto border border-l-0 border-r-0 border-t-0 border-solid"
        style={{
          scrollbarWidth: "none",
        }}
      >
        {tabs.map((tab) => {
          if (tab.isHidden) {
            return null;
          }
          return (
            <Tab
              key={tab.id}
              title={tab.title}
              isSelected={selectedTab === tab.id}
              className="w-auto"
              labelClassName="px-5"
              onClick={() => setSelectedTab(tab.id)}
              count={tab.counter}
            />
          );
        })}
      </div>
      <TabSearchPersistenceProvider>
        <div key={activeTab.id} className="animate-fade-in py-8">
          <activeTab.Component />
        </div>
      </TabSearchPersistenceProvider>
    </div>
  );
};
