import { useRestApiProvider } from "@jugl-web/rest-api";
import React, { useMemo, useState } from "react";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import ReCAPTCHA from "react-google-recaptcha";
import { Portal } from "@headlessui/react";
import {
  OrderFormFieldType,
  OrderFormFieldValue,
} from "@jugl-web/rest-api/orders/types";
import {
  ExpectedTaskCustomDropdownFieldValue,
  TaskCustomField,
} from "@jugl-web/rest-api/tasks";
import {
  TASK_ORDER_CUSTOMER_NAME,
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import { RadioGroupValue } from "@jugl-web/ui-components/cross-platform/forms/RadioGroup";
import { CheckboxGroupValue } from "@jugl-web/ui-components/cross-platform/forms/CheckboxGroup";
import { DEFAULT_WORKSPACE_CURRENCY } from "@jugl-web/utils";
import {
  getDueDateBasedOnDays,
  getDueDateBasedOnDaysAndTime,
  taskChecklistItemAdapters,
  templateChecklistItemAdapters,
} from "../../../tasks";
import linkExpiredAnimation from "./assets/error-animation.json";
import { OrderSubmitErrorAlert } from "./components/OrderSubmitErrorAlert";
import { OrderFormSubmission } from "../OrderFormSubmission";

export const OrderSubmitForm: React.FC<{
  entityId: string;
  formId: string;
  isGuestApp?: boolean;
  onSubmit?: () => void;
  captchaMode?: "grc" | "manual";
}> = ({ entityId, formId, isGuestApp, onSubmit, captchaMode }) => {
  const { ordersApi } = useRestApiProvider();
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const { isLoading, data, isError, isFetching } =
    ordersApi.useGuestOrderFormQuery(
      {
        entityId,
        formId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const {
    isLoading: isInitLoading,
    data: initData,
    isFetching: isInitFetching,
  } = ordersApi.useGuestInitQuery(
    { entityId },
    { refetchOnMountOrArgChange: true }
  );

  const { formFields } = useMemo(() => {
    let formFieldsResult: OrderFormFieldValue[] = [];
    if (data) {
      if (data.version === 1 && initData?.custom_fields) {
        const v1Fields = (
          data.fields as unknown as {
            id: string;
            order: number;
            required: boolean;
          }[]
        ).filter(
          (f) =>
            [
              TASK_ORDER_CUSTOMER_NAME,
              TASK_ORDER_PHONE_ID,
              TASK_ORDER_EMAIL_ID,
            ].includes(f.id) ||
            !!initData.custom_fields.props.value.find((cf) => cf.id === f.id)
        );
        formFieldsResult = v1Fields.map((f) => {
          if (
            [
              TASK_ORDER_CUSTOMER_NAME,
              TASK_ORDER_PHONE_ID,
              TASK_ORDER_EMAIL_ID,
            ].includes(f.id)
          ) {
            switch (f.id) {
              case TASK_ORDER_CUSTOMER_NAME:
                return {
                  id: f.id,
                  isRequired: f.required,
                  property: {
                    type: OrderFormFieldType.text,
                    value: {
                      label: "Your full name",
                      placeholder: "Enter",
                    },
                  },
                };
              case TASK_ORDER_EMAIL_ID:
              case TASK_ORDER_PHONE_ID:
                return {
                  id: f.id,
                  isRequired: true,
                  property: {
                    type: OrderFormFieldType.text,
                    value: {
                      label:
                        f.id === TASK_ORDER_EMAIL_ID
                          ? "Your email"
                          : "Your phone number",
                      placeholder: "Enter",
                    },
                  },
                };
              default:
            }
          }
          const customField = initData.custom_fields.props.value.find(
            (cf) => cf.id === f.id
          ) as TaskCustomField;
          let taskFieldProperty: OrderFormFieldValue["property"] = {
            type: OrderFormFieldType.text,
            value: {
              label: customField?.field,
              placeholder: "",
            },
          };
          if (customField.field_type === "date") {
            taskFieldProperty = {
              type: OrderFormFieldType.date,
              value: {
                label: customField.field,
              },
            };
          }
          if (customField.field_type === "dropdown") {
            taskFieldProperty = {
              type: OrderFormFieldType.dropdown,
              value: {
                label: customField.field,
                items: (
                  (customField.values ||
                    []) as unknown as ExpectedTaskCustomDropdownFieldValue[]
                ).map((value) => ({
                  id: value.value,
                  label: value.value,
                })),
              },
            };
          }
          return {
            id: f.id,
            order: f.order,
            isRequired: f.required,
            property: taskFieldProperty,
          };
        });
      }
      if (data.version === 2) {
        formFieldsResult = data.fields
          .map((field) => {
            if (field.property.type === OrderFormFieldType.services) {
              const filteredItems = field.property.value.items.filter((id) =>
                data.services.some((service) => service.id === id)
              );

              return {
                ...field,
                property: {
                  ...field.property,
                  value: {
                    ...field.property.value,
                    items: filteredItems,
                  },
                },
              };
            }
            return field;
          })
          .filter(
            (field) =>
              field.property.type !== OrderFormFieldType.services ||
              field.property.value.items.length > 0
          );
      }
    }
    return { formFields: formFieldsResult };
  }, [data, initData?.custom_fields]);

  const [submitOrderForm, { isLoading: isSubmitting }] =
    ordersApi.useGuestSubmitOrderMutation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const handleFormSubmit = async (
    formValues?: Record<string, string | Date>
  ) => {
    if (!entityId || !formValues || !data) {
      return;
    }
    let items: { inventory_id: string; qty: number }[] = [];
    const response: {
      id: string;
      name: string;
      value: string;
      type: string;
    }[] = [];
    Object.keys(formValues)
      .filter((key) => formFields.find((f) => f.id === key) && formValues[key])
      .forEach((key) => {
        const field = formFields.find((f) => f.id === key);
        if (field?.property.type === OrderFormFieldType.singleChoice) {
          const fieldValue = formValues[key] as RadioGroupValue;
          response.push({
            id: key,
            name: field.property.value?.label || "",
            value:
              (fieldValue.isOtherSelected
                ? fieldValue.otherValue
                : fieldValue.selectedOption) || "",
            type: field.property.type,
          });
          return;
        }
        if (field?.property.type === OrderFormFieldType.multiChoice) {
          const fieldValue = formValues[key] as CheckboxGroupValue;
          const options = [
            ...(fieldValue.selectedOptions || []),
            ...(fieldValue.isOtherSelected ? [fieldValue.otherValue] : []),
          ];
          response.push({
            id: key,
            name: field.property.value?.label || "",
            value: options.join(","),
            type: field.property.type,
          });
          return;
        }
        if (field?.property.type === OrderFormFieldType.services) {
          if (!formValues[key] || !Array.isArray(formValues[key])) {
            return;
          }
          // TODO: better typings
          items = [
            ...items,
            ...(formValues[key] as unknown as {
              inventory_id: string;
              qty: number;
            }[]),
          ];
          return;
        }
        let fieldValue = formValues[key];
        if (formValues[key] instanceof Date) {
          fieldValue = (formValues[key] as Date).toISOString();
        }
        response.push({
          id: key,
          name: field?.property.value?.label || "",
          value: fieldValue as string,
          type: field?.property.type || "",
        });
      });
    let grcToken: string | null | undefined = "";
    if (captchaMode === "grc") {
      grcToken = await (async () => {
        recaptchaRef.current?.reset();
        const captchaValue = await recaptchaRef.current?.executeAsync();
        return captchaValue;
      })();
    }
    const resp = await submitOrderForm({
      entityId,
      formId,
      isAuthenticated: !isGuestApp,
      data: {
        name: data.name,
        priority: data.priority || null,
        due_at: (() => {
          if (data.due_in !== null) {
            return (
              data.due_at
                ? getDueDateBasedOnDaysAndTime(data.due_at, data.due_in)
                : getDueDateBasedOnDays(data.due_in)
            ).toISOString();
          }
          return null;
        })(),
        response,
        items: items.length ? items : undefined,
        checklist: data.checklist
          .map(templateChecklistItemAdapters.toInternalModel)
          .map(taskChecklistItemAdapters.toBackendModel),
        has_chklist_chk: data.has_chklist_chk,
        captcha: grcToken
          ? {
              grc_token: grcToken,
            }
          : undefined,
      },
    });
    if (resp && "data" in resp) {
      onSubmit?.();
      setIsFormSubmitted(true);
    }
    if (resp && "error" in resp) {
      setIsErrorModalVisible(true);
    }
  };

  const $content = data ? (
    <OrderFormSubmission
      bannerImg={data.banner_img}
      title={data.title}
      discounts={{}}
      entityId={entityId}
      description={data.form_desc}
      fields={formFields}
      services={data.services}
      onSubmit={handleFormSubmit}
      isSubmitting={isSubmitting}
      isSubmitted={isFormSubmitted}
      onAddAnotherAnswer={() => setIsFormSubmitted(false)}
      currency={initData?.entity.currency || DEFAULT_WORKSPACE_CURRENCY}
    />
  ) : null;

  return (
    <>
      <DataLoadingWrapper
        isLoading={isLoading || isInitLoading || isFetching || isInitFetching}
        isError={isError}
        customErrorContentProps={{
          type: "custom",
          customTitle: "Form not found",
          customSubtitle: "This Form is missing or deleted",
          customImg: (
            <Lottie options={{ animationData: linkExpiredAnimation }} />
          ),
        }}
      >
        {$content}
      </DataLoadingWrapper>
      <OrderSubmitErrorAlert
        isOpen={isErrorModalVisible}
        onRequestClose={() => setIsErrorModalVisible(false)}
        workspaceName={initData?.entity.name || ""}
      />
      {captchaMode === "grc" ? (
        <Portal>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdMnvopAAAAAID5SLUbjhOfVLc2BDzMlvJPr3nn"
          />
        </Portal>
      ) : null}
    </>
  );
};
