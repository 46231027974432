import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { forwardRef } from "react";
import { FormControlLabel } from "../FormControlLabel";
import { Input, InputProps } from "../Input";

export interface TextFieldProps {
  variant?: "underline" | "outlined";
  type?: "text" | "number";
  label?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  autoFocus?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  maxLength?: InputProps["maxLength"];
  placeholder?: InputProps["placeholder"];
  autoComplete?: InputProps["autoComplete"];
  value?: InputProps["value"];
  onBlur?: InputProps["onBlur"];
  onChange?: InputProps["onChange"];
  onKeyDown?: InputProps["onKeyDown"];
  errorMessage?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      variant = "underline",
      type = "text",
      label,
      isRequired,
      isFullWidth,
      className,
      inputClassName,
      labelClassName,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const inputId = useUniqueDOMId();

    return (
      <div className={cx("flex flex-col items-start", className)}>
        {label && (
          <FormControlLabel
            htmlFor={inputId}
            isRequired={isRequired}
            className={cx("ml-2.5", labelClassName)}
          >
            {label}
          </FormControlLabel>
        )}
        <Input
          variant={variant}
          ref={ref}
          type={type}
          id={inputId}
          className={cx(isFullWidth && "w-full", inputClassName)}
          isError={!!errorMessage}
          {...props}
        />
        {errorMessage && (
          <span className="text-gradients-danger mt-1.5 text-sm leading-[140%] tracking-[0.14px]">
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
);
