import { Alert } from "@jugl-web/ui-components";
import { FC } from "react";
import Lottie from "react-lottie";
import animationData from "./assets/animation.json";

export type OrderSubmitErrorAlertProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  workspaceName: string;
};

export const OrderSubmitErrorAlert: FC<OrderSubmitErrorAlertProps> = ({
  isOpen,
  onRequestClose,
  workspaceName,
}) => (
  <Alert
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    header="Order Form"
    img={<Lottie options={{ animationData }} height={240} width={240} />}
    title="Something went wrong"
    content={
      <span>
        Please try again or reach out to the Admin of the{" "}
        <span className="text-primary-800">{workspaceName}</span> to proceed
        with Your Order
      </span>
    }
    buttons={[
      {
        text: "Okay",
        role: "close",
        color: "primary",
      },
    ]}
  />
);
