import {
  $applyNodeReplacement,
  $createTextNode,
  DOMConversionMap,
  DOMConversionOutput,
  LexicalNode,
  SerializedTextNode,
  TextNode,
} from "lexical";

const convertMark = (element: HTMLElement): DOMConversionOutput | null => {
  const text = element.textContent;

  if (!text) {
    return null;
  }

  return {
    node: $createTextNode(text).setFormat("highlight"),
  };
};

export class ExtendedTextNode extends TextNode {
  static getType(): string {
    return "extended-text";
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM();

    return {
      ...importers,
      mark: () => ({
        conversion: convertMark,
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return super.importJSON(serializedNode);
  }

  isSimpleText(): boolean {
    return this.__type === "extended-text" && this.__mode === 0;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: "extended-text",
    };
  }
}

export function $createExtendedTextNode(text: string): ExtendedTextNode {
  return $applyNodeReplacement(new ExtendedTextNode(text));
}

export function $createEmptySpaceTextNode(): ExtendedTextNode {
  return $createExtendedTextNode(" ");
}

export function $isExtendedTextNode(
  node: LexicalNode | null | undefined
): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode;
}
