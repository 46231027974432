import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { TaskChecklistItem, TaskCustomField } from "../tasks";
import { TaskTemplateChecklistItem } from "../tasks-templates";
import {
  OrderListItemDto,
  OrderListItemModel,
  transformOrderListItemDtoToModel,
} from "./models/OrderListItem";
import { OrdersApiTags } from "./tags";
import { CreateOrderFormPayload, OrderForm } from "./types";

export const addOrdersApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [OrdersApiTags.orders],
  });

  return apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      getOrders: builder.query<
        PaginatedResponse<OrderListItemModel>,
        PaginatedRequestParams<{ entityId: string }>
      >({
        query: ({ entityId, ...pageParams }) => ({
          url: `/api/auth/${entityId}/order/form`,
          params: {
            ...pageParams,
          },
        }),
        transformResponse: (response: PaginatedResponse<OrderListItemDto>) => ({
          ...response,
          data: response.data.map(transformOrderListItemDtoToModel),
        }),
      }),
      createOrderForm: builder.mutation<
        OrderListItemModel,
        {
          entityId: string;
          opts: CreateOrderFormPayload;
          banner?: File;
        }
      >({
        query: ({ entityId, opts, banner }) => {
          const dataForm = new FormData();
          dataForm.set("opts", JSON.stringify(opts));
          if (banner) {
            dataForm.set("banner_img_file", banner);
          }

          return {
            url: `/api/auth/${entityId}/order/form`,
            method: "POST",
            data: dataForm,
            silentError: true,
          };
        },
        transformResponse: (response: OrderListItemDto) =>
          transformOrderListItemDtoToModel(response),
      }),
      getOrderForm: builder.query<
        OrderForm,
        {
          entityId: string;
          formId: string;
        }
      >({
        query: ({ entityId, formId }) => ({
          url: `/api/auth/${entityId}/order/form/${formId}`,
        }),
        providesTags: (e1, e2, { formId }) => [
          { type: OrdersApiTags.orders, id: formId },
        ],
      }),
      deleteOrderForm: builder.mutation<
        void,
        { entityId: string; formId: string }
      >({
        query: ({ entityId, formId }) => ({
          url: `/api/auth/${entityId}/order/form/${formId}`,
          method: "DELETE",
          silentError: true,
        }),
      }),
      updateOrderForm: builder.mutation<
        void,
        {
          entityId: string;
          formId: string;
          opts: Partial<CreateOrderFormPayload>;
          banner?: File;
        }
      >({
        query: ({ entityId, formId, opts, banner }) => {
          const dataForm = new FormData();
          dataForm.set("opts", JSON.stringify(opts));
          if (banner) {
            dataForm.set("banner_img_file", banner);
          }

          return {
            url: `/api/auth/${entityId}/order/form/${formId}`,
            method: "PUT",
            data: dataForm,
            silentError: true,
          };
        },
        invalidatesTags: (e1, e2, { formId }) => [
          {
            type: OrdersApiTags.orders,
            id: formId,
          },
        ],
      }),
      guestOrderForm: builder.query<
        {
          title: string;
          msg: string;
          entity_id: string;
          form_desc: string;
          invite_code: string;
          banner_img: string;
          type: string;
          checklist: TaskTemplateChecklistItem[];
          fields: {
            id: string;
            order: number;
            required: boolean;
          }[];
          prefix: string;
          counter: number;
          short_url: string;
          name: string;
          desc: string;
          label_id: string | null;
          has_chklist_chk: boolean;
          assignees: string[];
          due_at: string | null;
          due_in: number | null;
          priority: "low" | "medium" | "high";
        },
        { entityId: string; formId: string }
      >({
        query: ({ entityId, formId }) => ({
          url: `/api/guest/${entityId}/order/form/${formId}`,
        }),
      }),
      guestInit: builder.query<
        {
          custom_fields: { props: { value: TaskCustomField[] } };
          entity: { id: string; name: string };
        },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/guest/${entityId}/order/init`,
        }),
      }),
      guestSubmitOrder: builder.mutation<
        void,
        {
          entityId: string;
          formId: string;
          isAuthenticated?: boolean;
          data: {
            name: string;
            priority: string;
            due_at: string | null;
            fields: Array<{ [key: string]: string }>;
            custom_fields: { [key: string]: string };
            has_chklist_chk?: boolean;
            checklist: TaskChecklistItem[];
            captcha?: {
              grc_token: string;
              agent?: string;
            };
          };
        }
      >({
        query: ({ entityId, formId, data, isAuthenticated }) => ({
          url: isAuthenticated
            ? `/api/auth/${entityId}/order/form/${formId}/submit`
            : `/api/guest/${entityId}/order/form/${formId}/submit`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }),
      }),
    }),
  });
};

export type OrdersApi = ReturnType<typeof addOrdersApi>;
