import React, { useMemo, useEffect, useRef, useState } from "react";
import { assignRefs, useTranslations } from "@jugl-web/utils";
import { BrandingLabel } from "@web-guest-src/modules/common/components/BrandingLabel";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { Tab } from "@jugl-web/ui-components/web/TabsLayout/components/Tab";
import { OrderResponseDto } from "@jugl-web/rest-api/orders/models/OrderResponse";
import { useTaskDetailsNavigation } from "@jugl-web/domain-resources/tasks/hooks/useTaskDetailsNavigation";
import { LoadingSpinner, PlainButton } from "@jugl-web/ui-components";
import { PageDivider } from "../PageDivider/PageDivider";
import { TaskAttachmentsSection } from "../TaskAttachmentsSection";
import { TaskDetailsSection } from "../TaskDetailsSection";
import { TaskOrderDetailsTab } from "../TaskOrderDetailsTab";
import { Section } from "../Section";
import { MobileCommentsDrawer } from "../MobileCommentsDrawer";
import { ReactComponent as CommentIcon } from "./assets/comment.svg";
import { TaskInvoiceHistoryTab } from "../TaskInvoiceHistoryTab";
import { TaskSubTasksSection } from "../TaskSubTasksSection";

export const MobileGuestTaskContent: React.FC<{
  taskData: GuestTaskDataModel;
  orderResponse?: OrderResponseDto;
}> = ({ taskData, orderResponse }) => {
  const { t } = useTranslations();

  const { task } = taskData;
  const [isCommentDrawerOpen, setCommentDrawerOpen] = useState(false);
  const [userSelectedTab, setUserSelectedTab] = useState<string | null>(null);

  const { activeTab, sectionRefs } = useTaskDetailsNavigation();

  const $pageWrapper = useRef<HTMLDivElement>(null);

  const tabRefs = useRef<{ [id: string]: HTMLDivElement | null }>({});
  const isUserInitiatedTabChange = useRef(false);
  const taskInfoRef = useRef<HTMLDivElement>(null);
  const subtasksRef = useRef<HTMLDivElement>(null);
  const orderDetailsRef = useRef<HTMLDivElement>(null);
  const attachmentsRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);

  const isAtBottom = useRef(false);

  const scrollToWithOffset = (ref: React.RefObject<HTMLElement>) => {
    if (!ref.current) return;

    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 60;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const tabs = useMemo(
    () => [
      {
        id: "task-info",
        title: t({
          id: "guest-task-page.task-info",
          defaultMessage: "Task Info",
        }),
        isActive: activeTab === "taskInfo",
        onClick: () => {
          taskInfoRef.current?.scrollIntoView({ behavior: "smooth" });

          scrollToWithOffset(taskInfoRef);
        },
      },
      {
        id: "subtasks",
        title: t({
          id: "guest-task-page.subtasks",
          defaultMessage: "Subtasks",
        }),
        counter: taskData.task.checklist.length,
        isActive: activeTab === "subtasks",
        onClick: () => {
          subtasksRef.current?.scrollIntoView({ behavior: "smooth" });

          scrollToWithOffset(subtasksRef);
        },
      },
      {
        id: "orderDetails",
        title: t({
          id: "guest-task-page.order-details",
          defaultMessage: "Order Details",
        }),
        isActive: activeTab === "orderDetails",
        counter: orderResponse?.invoice?.items?.length,
        isHidden: !task.order_resp,
        onClick: () => {
          orderDetailsRef.current?.scrollIntoView({ behavior: "smooth" });

          scrollToWithOffset(orderDetailsRef);
        },
      },
      {
        id: "attachments",
        title: t({
          id: "guest-task-page.attachments",
          defaultMessage: "Attachments",
        }),
        isActive: task.order_resp
          ? !isAtBottom.current && activeTab === "attachments"
          : activeTab === "attachments",
        counter: taskData.task.attachments.length,
        onClick: () => {
          attachmentsRef.current?.scrollIntoView({ behavior: "smooth" });

          scrollToWithOffset(attachmentsRef);
        },
      },
      {
        id: "history",
        title: t({
          id: "guest-task-page.history",
          defaultMessage: "History",
        }),
        isActive: isAtBottom.current || activeTab === "history",
        isHidden: !task.order_resp,
        counter: taskData.task.history_count,
        onClick: () => {
          historyRef.current?.scrollIntoView({
            behavior: "smooth",
          });

          scrollToWithOffset(historyRef);
        },
      },
    ],
    [
      activeTab,
      orderResponse?.invoice?.items?.length,
      t,
      task.order_resp,
      taskData.task.attachments.length,
      taskData.task.checklist.length,
      taskData.task.history_count,
    ]
  );

  useEffect(() => {
    const checkScrollPosition = () => {
      if (!$pageWrapper.current) return;

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const bottomOffset = 60;
      const atBottom = documentHeight - scrollTop - windowHeight < bottomOffset;

      if (atBottom !== isAtBottom.current) {
        isAtBottom.current = atBottom;

        if (atBottom && userSelectedTab === null && task.order_resp) {
          setUserSelectedTab("history");
        } else if (!atBottom && userSelectedTab === "history") {
          setUserSelectedTab(null);
        }
      }
    };

    window.addEventListener("scroll", checkScrollPosition);

    checkScrollPosition();

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [task.order_resp, userSelectedTab]);

  useEffect(() => {
    let lastScrollPosition = window.pageYOffset;

    const handleUserScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      if (Math.abs(currentScrollPosition - lastScrollPosition) > 20) {
        if (
          !isUserInitiatedTabChange.current &&
          userSelectedTab !== null &&
          userSelectedTab !== "history"
        ) {
          setUserSelectedTab(null);
        }
      }
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener("scroll", handleUserScroll);

    return () => {
      window.removeEventListener("scroll", handleUserScroll);
    };
  }, [userSelectedTab]);

  const [hasScrollLock, setHasScrollLock] = useState(false);
  const scrollYRef = useRef(0);

  useEffect(() => {
    // Fixing iOS bug with resizing when drawer is open and input is focused
    // Setting body to fixed and saving scroll position when mobile drawer is opened, on close restoring back to normal

    const currentScrollY =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
    if (isCommentDrawerOpen && !hasScrollLock) {
      scrollYRef.current = currentScrollY;

      document.body.style.position = "fixed";
      document.body.style.top = `-${currentScrollY}px`;
      document.body.style.overflow = "hidden";

      setHasScrollLock(true);
    }
    if (!isCommentDrawerOpen && hasScrollLock) {
      const savedPosition = scrollYRef.current;

      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.overflow = "";

      setTimeout(() => {
        window.scrollTo(0, savedPosition);
        scrollYRef.current = 0;
      }, 400);

      setHasScrollLock(false);
    }
  }, [isCommentDrawerOpen, hasScrollLock]);

  useEffect(() => {
    const activeTabObj = tabs.find(
      (tab) => tab.isActive || userSelectedTab === tab.id
    );

    if (activeTabObj && !isUserInitiatedTabChange.current) {
      const el = tabRefs.current[activeTabObj.id];

      if (el) {
        setTimeout(() => {
          el.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }, 100);
      }
    }
  }, [activeTab, userSelectedTab, tabs]);

  useEffect(() => {
    // Fix for chrome in iOS
    // Page is slightly scrolled down after first login
    window.scrollTo(0, -200);
  }, []);

  return (
    <div className="flex min-h-screen flex-col">
      <div
        className="sticky top-0 z-10 flex shrink-0 overflow-x-scroll bg-white"
        style={{
          scrollbarWidth: "none",
        }}
      >
        <div className="relative flex">
          <div className="z-10 flex px-4">
            {tabs.map((tab) => {
              if (tab.isHidden) return null;
              return (
                <div
                  key={tab.id}
                  ref={(el) => {
                    tabRefs.current[tab.id] = el;
                  }}
                >
                  <Tab
                    className="h-[52px]"
                    title={tab.title}
                    isSelected={
                      userSelectedTab === tab.id ||
                      (userSelectedTab === null && tab.isActive)
                    }
                    onClick={() => {
                      isUserInitiatedTabChange.current = true;
                      setUserSelectedTab(tab.id);
                      tab.onClick();

                      setTimeout(() => {
                        isUserInitiatedTabChange.current = false;
                      }, 500);
                    }}
                    labelClassName="pt-1"
                    count={tab.counter}
                  />
                </div>
              );
            })}
          </div>
          <div className="bg-grey-300 absolute bottom-0 left-0 right-0 h-1 w-full rounded-lg" />
        </div>
      </div>
      <div className="flex-1" ref={$pageWrapper}>
        <Section ref={assignRefs([taskInfoRef, sectionRefs.taskInfo])}>
          <TaskDetailsSection taskData={taskData} />
        </Section>

        <PageDivider />
        <Section ref={assignRefs([subtasksRef, sectionRefs.subtasks])}>
          <TaskSubTasksSection task={task} />
        </Section>

        <PageDivider />
        {task.order_resp && (
          <>
            <Section
              ref={assignRefs([orderDetailsRef, sectionRefs.orderDetails])}
            >
              <TaskOrderDetailsTab orderResponse={orderResponse} />
            </Section>
            <PageDivider />
          </>
        )}

        <Section ref={assignRefs([attachmentsRef, sectionRefs.attachments])}>
          <TaskAttachmentsSection task={task} />
        </Section>
        <PageDivider />

        {task.order_resp && (
          <>
            <Section
              ref={assignRefs([historyRef, sectionRefs.history])}
              className="px-0"
            >
              {orderResponse?.invoice?.id ? (
                <TaskInvoiceHistoryTab
                  entityId={task.entity_id}
                  invoiceId={orderResponse?.invoice?.id}
                  orderResponse={orderResponse}
                />
              ) : (
                <LoadingSpinner />
              )}
            </Section>
            <PageDivider />
          </>
        )}

        <div className="mb-8 px-4">
          <BrandingLabel />
        </div>
      </div>
      <MobileCommentsDrawer
        isOpen={isCommentDrawerOpen}
        onClose={() => setCommentDrawerOpen(false)}
        taskData={taskData}
      />
      {!isCommentDrawerOpen && (
        <PlainButton
          className="border-grey-[#7075771A] fixed right-4 z-20 rounded-2xl border-[4px] border-solid bg-white p-3"
          onClick={() => {
            isUserInitiatedTabChange.current = true;
            setTimeout(() => {
              isUserInitiatedTabChange.current = false;
            }, 1000);
            setCommentDrawerOpen(true);
          }}
          style={{
            top: "calc(100vh - 200px)",
            boxShadow: "0px 12px 16px 0px rgba(25, 25, 25, 0.14)",
          }}
        >
          <CommentIcon />
        </PlainButton>
      )}
    </div>
  );
};
