import noop from "lodash/noop";
import { useCallback, useState } from "react";

export interface ConfirmationDialogStateHandlers<
  TMetadata = Record<string, never>
> {
  onConfirm: () => void;
  onCancel?: () => void;
  metadata?: TMetadata;
}

export interface ConfirmationDialogState<TMetadata = Record<string, never>>
  extends ConfirmationDialogStateHandlers<TMetadata> {
  isOpen: boolean;
}

export type OpenConfirmationDialogFn<TMetadata = Record<string, never>> = (
  handlers: ConfirmationDialogStateHandlers<TMetadata>
) => void;

const INITIAL_STATE: ConfirmationDialogState = {
  isOpen: false,
  onConfirm: noop,
  onCancel: noop,
};

export const useConfirmationDialogState = <TMetadata = Record<string, never>>(
  initialState?: Partial<ConfirmationDialogState<TMetadata>>
) => {
  const [state, setState] = useState(() => ({
    ...INITIAL_STATE,
    ...initialState,
  }));

  const open = useCallback<OpenConfirmationDialogFn<TMetadata>>((handlers) => {
    setState({ ...handlers, isOpen: true });
  }, []);

  const close = useCallback(() => {
    setState((previousState) => ({ ...previousState, isOpen: false }));
  }, []);

  const confirm = useCallback(
    (options?: { closeOnceConfirmed: boolean }) => {
      state.onConfirm();

      if (options?.closeOnceConfirmed) {
        close();
      }
    },
    [close, state]
  );

  const cancel = useCallback(
    (options?: { closeOnceCanceled: boolean }) => {
      state.onCancel?.();

      if (options?.closeOnceCanceled) {
        close();
      }
    },
    [close, state]
  );

  return {
    isOpen: state.isOpen,
    metadata: state.metadata,
    open,
    close,
    confirm,
    cancel,
  };
};
