import { cx } from "@jugl-web/utils";
import { forwardRef, ReactNode } from "react";

export const Section = forwardRef<
  HTMLDivElement,
  { children: ReactNode; className?: string }
>(({ children, className }, ref) => (
  <div ref={ref} className={cx("bg-white px-4", className)}>
    {children}
  </div>
));
