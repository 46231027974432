import { useRestApiProvider } from "@jugl-web/rest-api";
import React, { useMemo, useState } from "react";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { ExpectedTaskCustomDropdownFieldValue } from "@jugl-web/rest-api/tasks";
import ReCAPTCHA from "react-google-recaptcha";
import { Portal } from "@headlessui/react";
import {
  getDueDateBasedOnDays,
  getDueDateBasedOnDaysAndTime,
  taskChecklistItemAdapters,
  templateChecklistItemAdapters,
} from "../../../tasks";
import { OrderFormCustomField } from "../../types";
import {
  OrderFormSubmission,
  OrderFormSubmissionValues,
} from "../OrderFormSubmission/OrderFormSubmission";
import linkExpiredAnimation from "./assets/error-animation.json";
import { OrderSubmitErrorAlert } from "./components/OrderSubmitErrorAlert";

export const OrderSubmitForm: React.FC<{
  entityId: string;
  formId: string;
  isGuestApp?: boolean;
  onSubmit?: () => void;
  captchaMode?: "grc" | "manual";
}> = ({ entityId, formId, isGuestApp, onSubmit, captchaMode }) => {
  const { ordersApi } = useRestApiProvider();
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const { isLoading, data, isError, isFetching } =
    ordersApi.useGuestOrderFormQuery(
      {
        entityId,
        formId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const {
    isLoading: isInitLoading,
    data: initData,
    isFetching: isInitFetching,
  } = ordersApi.useGuestInitQuery(
    { entityId },
    { refetchOnMountOrArgChange: true }
  );

  const [submitOrderForm, { isLoading: isSubmitting }] =
    ordersApi.useGuestSubmitOrderMutation();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const formFields = useMemo(() => {
    const result: OrderFormCustomField[] = [];
    data?.fields.forEach((item) => {
      if (item.id === TASK_ORDER_EMAIL_ID) {
        result.push({
          id: item.id,
          name: "Email",
          type: "text",
          order: -1,
          isShownInCard: false,
          isRequired: true,
        } as OrderFormCustomField);
        return;
      }
      const customField = initData?.custom_fields?.props?.value.find(
        (customFieldItem) => customFieldItem.id === item.id
      );
      if (!customField) {
        return;
      }
      result.push({
        id: customField.id,
        name: customField.field,
        type: customField.field_type,
        order: item.order,
        isShownInCard: false,
        values:
          customField.values as unknown as ExpectedTaskCustomDropdownFieldValue[],
        isRequired: item.required,
      });
    });
    return result;
  }, [data?.fields, initData?.custom_fields?.props?.value]);

  const $content = data ? (
    <OrderFormSubmission
      isGuestApp={isGuestApp}
      onSubmit={async (formValues) => {
        if (!entityId || !formValues) {
          return;
        }
        const customFields: Record<string, string> = {};
        Object.keys(formValues).forEach((key) => {
          if (formValues[key] instanceof Date) {
            customFields[key] = (formValues[key] as Date).toISOString();
            return;
          }
          customFields[key] = formValues[key] as string;
        });
        let grcToken: string | null | undefined = "";
        if (captchaMode === "grc") {
          grcToken = await (async () => {
            recaptchaRef.current?.reset();
            const captchaValue = await recaptchaRef.current?.executeAsync();
            return captchaValue;
          })();
        }
        const resp = await submitOrderForm({
          entityId,
          formId,
          isAuthenticated: !isGuestApp,
          data: {
            name: data.name,
            priority: data.priority || null,
            due_at: (() => {
              if (data.due_in !== null) {
                return (
                  data.due_at
                    ? getDueDateBasedOnDaysAndTime(data.due_at, data.due_in)
                    : getDueDateBasedOnDays(data.due_in)
                ).toISOString();
              }
              return null;
            })(),
            fields: Object.keys(formValues).map((key) => ({
              id: key,
              value: formValues[key] as string,
            })),
            checklist: data.checklist
              .map(templateChecklistItemAdapters.toInternalModel)
              .map(taskChecklistItemAdapters.toBackendModel),
            custom_fields: customFields,
            has_chklist_chk: data.has_chklist_chk,
            captcha: grcToken
              ? {
                  grc_token: grcToken,
                }
              : undefined,
          },
        });
        if (resp && "data" in resp) {
          if (isGuestApp) {
            setIsSubmitted(true);
          }
          onSubmit?.();
        }
        if (resp && "error" in resp) {
          setIsErrorModalVisible(true);
        }
      }}
      isSubmitted={isGuestApp ? isSubmitted : undefined}
      submittedMessage={data.msg}
      onReset={isGuestApp ? () => setIsSubmitted(false) : undefined}
      isLoading={isSubmitting}
      values={{
        title: data.title || "",
        description: data.form_desc || "",
        image: data.banner_img,
        fields: (formFields as OrderFormSubmissionValues["fields"]) || [],
      }}
    />
  ) : null;

  return (
    <>
      <DataLoadingWrapper
        isLoading={isLoading || isInitLoading || isFetching || isInitFetching}
        isError={isError}
        customErrorContentProps={{
          type: "custom",
          customTitle: "Form not found",
          customSubtitle: "This Form is missing or deleted",
          customImg: (
            <Lottie options={{ animationData: linkExpiredAnimation }} />
          ),
        }}
      >
        {isGuestApp ? (
          <div className="mx-auto h-full px-4 pt-[60px]">
            <div className="mx-auto flex max-w-[720px] flex-col items-center rounded-xl bg-white pb-12 pt-6 md:min-h-full md:rounded-t-xl md:p-6">
              {$content}
            </div>
          </div>
        ) : (
          $content
        )}
      </DataLoadingWrapper>
      <OrderSubmitErrorAlert
        isOpen={isErrorModalVisible}
        onRequestClose={() => setIsErrorModalVisible(false)}
        workspaceName={initData?.entity.name || ""}
      />
      {captchaMode === "grc" ? (
        <Portal>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdMnvopAAAAAID5SLUbjhOfVLc2BDzMlvJPr3nn"
          />
        </Portal>
      ) : null}
    </>
  );
};
