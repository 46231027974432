import React from "react";
import { useAppVariant, cx } from "@jugl-web/utils";

export const SectionHeader: React.FC<{
  icon: React.ReactNode;
  title: string;
}> = ({ icon, title }) => {
  const { isMobile } = useAppVariant();
  return (
    <div className="flex items-center gap-2">
      {icon}
      <span
        className={cx("text-dark font-secondary text-xl font-medium", {
          "text-base": isMobile,
        })}
      >
        {title}
      </span>
    </div>
  );
};
