import React, { useEffect, useMemo, useRef, useState } from "react";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { TaskActivity, TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { usePrevious } from "react-use";
import { CommentItem } from "../CommentItem/CommentItem";
import { ReactComponent as CommentsIcon } from "./assets/comments.svg";

export const TaskCommentsSection: React.FC<{
  taskData: GuestTaskDataModel;
  searchQuery?: string;
  handleEditComment: (comment: TaskActivity) => void;
}> = ({ taskData, searchQuery, handleEditComment }) => {
  const { t } = useTranslations();
  const { isWeb } = useAppVariant();

  const [webSearchQuery, setWebSearchQuery] = useState<string>();
  const [isSearchInputExpanded, setIsSearchInputExpanded] = useState(false);
  const activityItems = useMemo(
    () =>
      [...taskData.activity]
        .reverse()
        .filter((item) => item.action_details?.action !== "guest_opened"),
    [taskData.activity]
  );

  const $comments = useRef<HTMLDivElement>(null);

  const filteredComments = useMemo(
    () =>
      activityItems.filter((item) => {
        if (item.action_details?.action === "guest_feedback") {
          const comment =
            item.action_details?.changes.content.feedback?.toLowerCase() || "";
          const query = (searchQuery || webSearchQuery || "").toLowerCase();
          return comment?.includes(query);
        }
        if (
          item.action_details?.action === "guest_comment" ||
          item.action_details?.action === "guest_comment_reply"
        ) {
          const comment =
            item.action_details?.changes?.content?.toLowerCase() || "";
          const query = (searchQuery || webSearchQuery || "").toLowerCase();
          return comment?.includes(query);
        }
        return true;
      }),
    [activityItems, searchQuery, webSearchQuery]
  );

  const previousComments = usePrevious(filteredComments);

  useEffect(() => {
    const $commentsWrapper = $comments.current?.parentNode as HTMLDivElement;
    $commentsWrapper?.scrollTo(0, $commentsWrapper.scrollHeight);
  }, []);

  useEffect(() => {
    if ((previousComments?.length || 0) >= (filteredComments.length || 0))
      return undefined;
    const $commentsWrapper = $comments.current?.parentNode as HTMLDivElement;

    const resizeObserver = new ResizeObserver(() => {
      $commentsWrapper?.scrollTo({
        top: $commentsWrapper.scrollHeight,
        behavior: "smooth",
      });

      resizeObserver.disconnect();
    });

    if ($commentsWrapper) {
      resizeObserver.observe($commentsWrapper);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [filteredComments, previousComments]);

  const noResults = (
    <div className="font-secondary w-full pt-4 text-center font-medium leading-4 text-[#828282]">
      {t({
        id: "common.no-results",
        defaultMessage: "No results",
      })}
    </div>
  );
  const $content =
    !activityItems?.length && !(searchQuery || webSearchQuery) ? (
      <div ref={$comments} className="text-dark-500 mt-7 px-8 text-xs">
        {t({
          id: "guest-task-page.no-comments-yet",
          defaultMessage: "No Comments yet",
        })}
      </div>
    ) : (
      <div className={cx("flex flex-col", !isWeb && "gap-2")} ref={$comments}>
        {filteredComments.map((activity) => (
          <CommentItem
            key={activity.id}
            activity={activity}
            entityId={taskData.entity.id}
            taskId={taskData.task.id}
            isTaskCompleted={
              taskData.task.status === TaskDefaultStatusId.completed
            }
            handleEditComment={() => handleEditComment(activity)}
            searchQuery={webSearchQuery || searchQuery}
          />
        ))}
      </div>
    );

  return isWeb ? (
    <>
      <div className="sticky top-0 z-50 flex items-center justify-between gap-2 bg-white py-[14px] px-8">
        {!isSearchInputExpanded && (
          <div className="flex items-center gap-2">
            <CommentsIcon />
            <span className="text-dark font-secondary text-sm leading-3 tracking-[0.14px]">
              {t({
                id: "guest-task-page.comments",
                defaultMessage: "Comments",
              })}
            </span>
          </div>
        )}
        <div
          className={cx("overflow-hidden", {
            "w-full": isSearchInputExpanded,
          })}
        >
          <ExpandableSearchBar
            defaultValue={webSearchQuery}
            color="grey"
            size="md"
            expandedClassName="w-full"
            className="w-full"
            onExpand={setIsSearchInputExpanded}
            onSearch={(value) => setWebSearchQuery(value)}
          />
        </div>
      </div>
      <div className="bg-grey-200 h-px" />
      {$content}
      {filteredComments.length === 0 && webSearchQuery && noResults}
    </>
  ) : (
    <>
      {$content}
      {filteredComments.length === 0 && searchQuery && noResults}
    </>
  );
};
