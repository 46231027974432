import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useMemo } from "react";

export interface TaskStatusDetails {
  id: TaskDefaultStatus | string;
  label: string;
}

export const useDefaultTaskStatuses = () => {
  const { t } = useTranslations();

  const defaultStatusDetailsById = useMemo<
    Record<TaskDefaultStatus, TaskStatusDetails>
  >(
    () => ({
      [TaskDefaultStatus.notStarted]: {
        id: TaskDefaultStatus.notStarted,
        label: t({
          id: "tasks-page.pending-status",
          defaultMessage: "Pending",
        }),
      },
      [TaskDefaultStatus.inProgress]: {
        id: TaskDefaultStatus.inProgress,
        label: t({
          id: "tasks-page.in-progress-status",
          defaultMessage: "In progress",
        }),
      },
      [TaskDefaultStatus.completed]: {
        id: TaskDefaultStatus.completed,
        label: t({
          id: "tasks-page.completed-status",
          defaultMessage: "Completed",
        }),
      },
    }),
    [t]
  );

  return { defaultStatusDetailsById };
};
