import {
  InventoryItemCategory,
  InventoryItemUnit,
  InventoryItem,
} from "../inventory";
import { DetailedTask } from "../tasks";
import { TaskTemplateChecklistItem } from "../tasks-templates";

export enum OrderFormFieldType {
  text = "text",
  paragraph = "paragraph",
  dropdown = "dropdown",
  date = "date",
  singleChoice = "singleChoice",
  multiChoice = "multiChoice",
  services = "services",
  serviceConfig = "serviceConfig",
}

export type OrderFormTextFieldValue = {
  label: string;
  placeholder: string;
};

export type OrderFormDropdownFieldValue = {
  label: string;
  items: {
    id: string;
    label: string;
  }[];
};

export type OrderFormServicesFieldValue = {
  label: string;
  items: string[];
};

export type OrderFormServiceConfigFieldValue = {
  label: string;
  items: {
    discount: string;
  };
};

export type OrderFormDateFieldValue = {
  label: string;
};

export type OrderFormSingleChoiceFieldValue = OrderFormDropdownFieldValue & {
  hasOtherOption: boolean;
};

export type OrderFormMultiChoiceFieldValue = OrderFormDropdownFieldValue & {
  hasOtherOption: boolean;
};

export type OrderFormParagraphFieldValue = OrderFormTextFieldValue;

export type OrderFormFieldProperty =
  | {
      type: OrderFormFieldType.text;
      value: OrderFormTextFieldValue;
    }
  | {
      type: OrderFormFieldType.paragraph;
      value: OrderFormParagraphFieldValue;
    }
  | {
      type: OrderFormFieldType.dropdown;
      value: OrderFormDropdownFieldValue;
    }
  | {
      type: OrderFormFieldType.date;
      value: OrderFormDateFieldValue;
    }
  | {
      type: OrderFormFieldType.singleChoice;
      value: OrderFormSingleChoiceFieldValue;
    }
  | {
      type: OrderFormFieldType.multiChoice;
      value: OrderFormMultiChoiceFieldValue;
    }
  | {
      type: OrderFormFieldType.services;
      value: OrderFormServicesFieldValue;
    }
  | {
      type: OrderFormFieldType.serviceConfig;
      value: OrderFormServiceConfigFieldValue;
    };

export type OrderFormFieldValue = {
  id: string;
  isRequired: boolean;
  property: OrderFormFieldProperty;
};

type OrderFormTaskDetails = Pick<
  DetailedTask,
  | "assignees"
  | "name"
  | "priority"
  | "desc"
  | "label_id"
  | "has_chklist_chk"
  | "board_id"
  | "due_at"
> & {
  due_in: number | null;
  checklist: TaskTemplateChecklistItem[];
};

export type CreateOrderFormPayload = OrderFormTaskDetails & {
  title: string;
  form_desc?: string;
  prefix: string;
  type: "apt";
  services: string[];
  fields: OrderFormFieldValue[];
  service_config?: {
    [key: string]: {
      discount: string;
    };
  };
};

export type OrderForm = OrderFormTaskDetails & {
  id: string;
  entity_id: string;
  type: "apt";
  fields: OrderFormFieldValue[];
  counter: number;
  prefix: string;
  services: (InventoryItem & {
    discount: {
      discount: number | null;
    };
  })[];
  service_config: Record<string, { discount: string }>;
  banner_img?: string;
  title: string;
  form_desc: string;
  version: number;
};

export type OrderResponseInvoiceItem = {
  inventory_id: string;
  name: string;
  desc: string;
  qty: number;
  unit: InventoryItemUnit;
  total: number;
  price_type: string | null;
  img:
    | {
        id: string;
        order: number;
        path: string;
      }[];
  category: InventoryItemCategory;
  price: number;
  discount: {
    discount: number | null;
  };
};

export type OrderResponseInvoice = {
  id: string;
  entity_id: string;
  status: string;
  signature: string | null;
  total: number;
  items: OrderResponseInvoiceItem[];
};

export type OrderResponseField = {
  id: string;
  name: string;
  type: string;
  value: string;
};
