import { useCallback, useEffect, useState } from "react";
import { Subject } from "rxjs";
import { getStorageItemWithFallback, saveItemToStorage } from "../storage";

const storageUpdate$ = new Subject<{ key: string }>();

const produceUseStorage =
  (storageGetter: () => Storage) =>
  <TValue>(key: string, initialValue: TValue) => {
    const storage = storageGetter();

    const [storedValue, setStoredValue] = useState(() =>
      getStorageItemWithFallback(key, initialValue, storage)
    );

    const setValue = useCallback(
      (value: TValue | ((previousValue: TValue) => TValue)) => {
        setStoredValue((previousStoredValue) => {
          const resolvedValue =
            value instanceof Function ? value(previousStoredValue) : value;

          saveItemToStorage(key, resolvedValue, storage);
          storageUpdate$.next({ key });

          return resolvedValue;
        });
      },
      [key, storage]
    );

    useEffect(() => {
      const subscription = storageUpdate$.subscribe((event) => {
        if (event.key === key) {
          setStoredValue((previousStoredValue) =>
            getStorageItemWithFallback(key, previousStoredValue, storage)
          );
        }
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [key, storage]);

    return [storedValue, setValue] as const;
  };

export const useLocalStorage = produceUseStorage(() => localStorage);
export const useSessionStorage = produceUseStorage(() => sessionStorage);
