import { OrderResponseDto } from "@jugl-web/rest-api/orders/models/OrderResponse";
import { TaskActivity, TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import React, { useRef, useState } from "react";
import { TaskCommentsInput } from "../TaskCommentsInput";
import { TaskCommentsSection } from "../TaskCommentsSection";
import { TaskDetailsSection } from "../TaskDetailsSection";
import { TaskFeedbackSection } from "../TaskFeedbackSection";
import { TaskTabs } from "../TaskTabs";
import { WebTaskPageHeader } from "../WebTaskPageHeader";

export const WebGuestTaskContent: React.FC<{
  taskData: GuestTaskDataModel;
  orderResponse?: OrderResponseDto;
}> = ({ taskData, orderResponse }) => {
  const { task } = taskData;
  const isTaskCompleted = task?.status === TaskDefaultStatusId.completed;
  const $sideBarRef = useRef<HTMLDivElement>(null);
  const [editedComment, setEditedComment] = useState<TaskActivity>();

  return (
    <MultiSectionLayout
      className="h-screen bg-[#ECEFF0]"
      header={<WebTaskPageHeader />}
    >
      <div className="mx-auto h-full  px-4 pt-6">
        <div className="grid h-full justify-center gap-1 md:grid-cols-[auto_380px] lg:grid-cols-[auto_480px]">
          <div
            className="flex min-h-full grow flex-col gap-1 overflow-y-auto"
            style={{
              scrollbarGutter: "stable",
            }}
          >
            <div
              className="w-[1000px] grow rounded-tl-xl bg-white p-8 pt-2"
              style={{
                scrollbarGutter: "stable",
              }}
            >
              <TaskDetailsSection taskData={taskData} />
              <TaskTabs taskData={taskData} orderResponse={orderResponse} />
            </div>
          </div>
          <div className="w-full overflow-hidden rounded-tr-lg bg-white">
            <MultiSectionLayout
              ref={$sideBarRef}
              footer={
                isTaskCompleted ? (
                  <TaskFeedbackSection taskData={taskData} />
                ) : (
                  <div
                    className="px-4"
                    style={{
                      boxShadow: "1px -6px 10px 0px rgba(18, 22, 34, 0.08)",
                    }}
                  >
                    <TaskCommentsInput
                      entityId={task.entity_id}
                      taskId={task.id}
                      editedComment={editedComment}
                      cancelEditComment={() => setEditedComment(undefined)}
                    />
                  </div>
                )
              }
            >
              <TaskCommentsSection
                taskData={taskData}
                handleEditComment={setEditedComment}
              />
            </MultiSectionLayout>
          </div>
        </div>
      </div>
    </MultiSectionLayout>
  );
};
