import { cx, useTranslations } from "@jugl-web/utils";
import {
  DPConfig,
  DPDatesConfig,
  useDatePickerContext,
} from "@rehookify/datepicker";
import startOfDay from "date-fns/startOfDay";
import { isMonday, isSunday, format, differenceInMonths } from "date-fns";
import { FC, useMemo } from "react";
import { Button } from "../../../Button";
import { InteractiveContainer } from "../../../InteractiveContainer";
import { PlainButton } from "../../../PlainButton";
import { ReactComponent as ChevronLeftIcon } from "../../assets/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";
import { PickerBaseProps } from "../../types";
import { TimePicker } from "../TimePicker";

interface DayPickerProps extends PickerBaseProps {
  initialDate?: Date | Date[];
  isDateRemovable?: boolean;
  removeDateLabel?: string;
  maxDate?: Date;
  minDate?: Date;
  showTimePicker?: boolean;
  mode?: DPDatesConfig["mode"];
  maxRangeInMonths?: number;
  showCancelButton?: boolean;
  onDatesChange: DPConfig["onDatesChange"];
  onSubmit?: (date: Date | null, date2?: Date | null) => void;
  onDaySelect?: (date: Date) => void;
  onClose?: () => void;
}

export const DayPicker: FC<DayPickerProps> = ({
  initialDate,
  isDateRemovable,
  removeDateLabel,
  maxDate,
  minDate,
  showTimePicker,
  showCancelButton,
  maxRangeInMonths,
  onViewChange,
  onDatesChange,
  mode,
  onSubmit,
  onDaySelect,
  onClose,
}) => {
  const {
    data: { calendars, weekDays, selectedDates },
    propGetters: { subtractOffset, addOffset, dayButton },
  } = useDatePickerContext();

  const { month, year, days } = calendars[0];

  const { t } = useTranslations();

  const isDateSelected = selectedDates.length > 0;

  const isSubmitButtonDisabled = useMemo(() => {
    if (!isDateSelected) {
      return true;
    }

    const [selectedDate, selectedDate2] = selectedDates;

    if (mode === "range" && maxRangeInMonths) {
      const isRangeExceeded =
        differenceInMonths(selectedDate2, selectedDate) > maxRangeInMonths;
      return isRangeExceeded;
    }
    const isBeforeMinDate =
      minDate && startOfDay(selectedDate) < startOfDay(minDate);
    const isAfterMaxDate =
      maxDate && startOfDay(selectedDate) > startOfDay(maxDate);

    return isBeforeMinDate || isAfterMaxDate;
  }, [isDateSelected, maxDate, maxRangeInMonths, minDate, mode, selectedDates]);

  return (
    <>
      <div className="flex h-10 w-full items-center justify-between">
        <PlainButton
          {...subtractOffset({ months: 1 })}
          className="hover:bg-grey-100 h-10 w-10 rounded-full transition-colors disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
        >
          <ChevronLeftIcon />
        </PlainButton>
        <div className="flex items-center justify-center gap-4">
          <InteractiveContainer
            className="bg-grey-100 text-dark-800 hover:bg-grey-200 flex h-10 items-center rounded-lg px-3 font-medium transition-colors"
            onClick={() => onViewChange("monthPicker")}
          >
            {month}
          </InteractiveContainer>
          <InteractiveContainer
            className="bg-grey-100 text-dark-800 hover:bg-grey-200 flex h-10 items-center rounded-lg px-3 font-medium transition-colors"
            onClick={() => onViewChange("yearPicker")}
          >
            {year}
          </InteractiveContainer>
        </div>
        <PlainButton
          {...addOffset({ months: 1 })}
          className="hover:bg-grey-100 h-10 w-10 rounded-full transition-colors disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
        >
          <ChevronRightIcon />
        </PlainButton>
      </div>
      <div className="mt-8 grid grid-cols-7">
        {weekDays.map((day) => (
          <span
            key={`${month}-${day}`}
            className="text-center text-xs leading-[16px]"
            style={{ color: "rgba(60, 60, 67, 0.60)" }}
          >
            {day}
          </span>
        ))}
      </div>
      <div className="mt-1.5 grid grid-cols-7 gap-1">
        {days.map((dpDay) => {
          const { onClick, ...buttonProps } = dayButton(dpDay);
          const isInRange = dpDay.range === "in-range";
          return (
            <button
              key={dpDay.$date.toDateString()}
              type="button"
              className={cx(
                "flex h-10 shrink-0 cursor-pointer items-center justify-center rounded-full border-none transition-colors",
                "hover:bg-grey-100  relative bg-transparent text-black",
                !dpDay.inCurrentMonth && "text-grey-500",
                dpDay.now &&
                  "bg-primary-50 text-primary hover:bg-primary-50 font-semibold",
                dpDay.disabled &&
                  "text-grey-500 cursor-not-allowed bg-transparent hover:bg-transparent",
                dpDay.selected &&
                  "bg-primary hover:bg-primary font-semibold text-white",
                dpDay.range === "in-range" &&
                  "bg-primary hover:bg-primary rounded-none text-white",
                ((isInRange && isMonday(dpDay.$date)) ||
                  dpDay.range === "range-start") &&
                  "bg-primary rounded-tl-[22px] rounded-bl-[22px] rounded-tr-none rounded-br-none",
                ((isInRange && isSunday(dpDay.$date)) ||
                  dpDay.range === "range-end") &&
                  "bg-primary rounded-tr-[22px] rounded-br-[22px] rounded-tl-none rounded-bl-none"
              )}
              onClick={(event) => {
                onClick?.(event);
                onDaySelect?.(dpDay.$date);
              }}
              {...buttonProps}
            >
              {(isInRange || dpDay.range === "range-end") && (
                <div
                  className={cx({
                    "bg-primary absolute top-0 bottom-0 left-[-6px] h-full w-[8px]":
                      !isMonday(dpDay.$date),
                  })}
                />
              )}
              {dpDay.day}
              <div />
            </button>
          );
        })}
      </div>
      {showTimePicker && (
        <TimePicker
          className="mt-6"
          minDate={minDate}
          maxDate={maxDate}
          onDatesChange={onDatesChange}
        />
      )}
      {onSubmit && (
        <div className="mt-8 flex flex-col gap-4">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            isDisabled={isSubmitButtonDisabled}
            onClick={() => {
              onSubmit?.(selectedDates[0], selectedDates[1]);
              onClose?.();
            }}
          >
            {mode !== "range" &&
              t({ id: "common.select", defaultMessage: "Select" })}
            {mode === "range" &&
              `${t({
                id: "common.apply",
                defaultMessage: "Apply",
              })} ${selectedDates[0] ? format(selectedDates[0], "MMM d") : ""}${
                selectedDates[1]
                  ? ` - ${format(selectedDates[1], "MMM d")}`
                  : ""
              }`}
          </Button>
          {showCancelButton && (
            <Button
              variant="contained"
              color="grey"
              fullWidth
              onClick={() => {
                onClose?.();
              }}
            >
              {t({ id: "common.cancel", defaultMessage: "Cancel" })}
            </Button>
          )}
          {initialDate && isDateRemovable && (
            <Button
              variant="contained"
              color="grey"
              className="text-tertiary-900"
              fullWidth
              onClick={() => {
                onSubmit?.(null);
                onClose?.();
              }}
            >
              {removeDateLabel ||
                t({
                  id: "date-picker-component.remove-date",
                  defaultMessage: "Remove date",
                })}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
