import { cx, useUniqueId } from "@jugl-web/utils";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { KeyboardEvent, forwardRef, useMemo, useRef } from "react";
import { MentionTextareaControllerPlugin } from "./MentionTextareaControllerPlugin";
import { MentionsPlugin } from "./MentionsPlugin";
import { OnEnterPlugin } from "./OnEnterPlugin";
import { MentionTextareaHandle, MentionTextareaProps } from "./types";
import { getLexicalConfig } from "./utils";
import { RemoteMentionsPlugin } from "./RemoteMentionsPlugin";

export const MentionTextarea = forwardRef<
  MentionTextareaHandle,
  MentionTextareaProps
>(
  (
    {
      mentions,
      mentionListType = "container-pinned",
      isDisabled = false,
      placeholder,
      classes,
      onRawTextChange,
      onPaste,
      onEnter,
      onEscape,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const namespace = useUniqueId();

    const config = useMemo(
      () => getLexicalConfig(namespace, isDisabled),
      [namespace, isDisabled]
    );

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Escape") {
        onEscape?.(event);
      }
    };

    return (
      <LexicalComposer initialConfig={config}>
        <div className={cx("relative", classes?.container)}>
          <div ref={containerRef} />
          <PlainTextPlugin
            contentEditable={
              <ContentEditable
                className={cx("p-2", classes?.contentEditable)}
                onPaste={onPaste}
                onKeyDown={handleKeyDown}
              />
            }
            placeholder={
              placeholder ? (
                <div
                  className={cx(
                    "pointer-events-none absolute left-2 top-2 select-none overflow-hidden",
                    classes?.placeholder
                  )}
                >
                  {placeholder}
                </div>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
        <HistoryPlugin />
        {(mentions?.type === "manual" && (
          <MentionsPlugin
            mentions={mentions.mentions}
            listType={mentionListType}
            containerRef={containerRef}
          />
        )) ||
          ""}
        {(mentions?.type === "remote" && (
          <RemoteMentionsPlugin
            fetchParams={mentions.fetchParams}
            listType={mentionListType}
            containerRef={containerRef}
            meId={mentions.meId}
          />
        )) ||
          ""}
        <MentionTextareaControllerPlugin
          handle={ref}
          onRawTextChange={onRawTextChange}
        />
        <OnEnterPlugin onEnter={onEnter} />
        <ListPlugin />
      </LexicalComposer>
    );
  }
);
