import { useCallback, useState, useMemo } from "react";
import { useInView } from "react-intersection-observer";

type TaskDetailsTab =
  | "taskInfo"
  | "subtasks"
  | "orderDetails"
  | "attachments"
  | "history";

export const useTaskDetailsNavigation = () => {
  const [intersectionRatioByTabId, setIntersectionRatioByTabId] = useState<
    Partial<Record<TaskDetailsTab, number>>
  >({});

  const updateVisibleSections = useCallback(
    (tab: TaskDetailsTab) =>
      (inView: boolean, entry: IntersectionObserverEntry) => {
        setIntersectionRatioByTabId((prev) => ({
          ...prev,
          [tab]: inView ? entry.intersectionRatio : 0,
        }));
      },
    []
  );

  const [taskInfoSectionRef] = useInView({
    onChange: updateVisibleSections("taskInfo"),
    threshold: [0, 0.5, 1],
  });
  const [subtasksSectionRef] = useInView({
    onChange: updateVisibleSections("subtasks"),
    threshold: [0, 0.5, 1],
  });
  const [orderDetailsSectionRef] = useInView({
    onChange: updateVisibleSections("orderDetails"),
    threshold: [0, 0.5, 1],
  });
  const [attachmentsSectionRef] = useInView({
    onChange: updateVisibleSections("attachments"),
    threshold: [0, 0.5, 1],
  });
  const [historySectionRef] = useInView({
    onChange: updateVisibleSections("history"),
    threshold: [0, 0.5, 1],
  });

  const activeTab = useMemo<TaskDetailsTab>(
    () =>
      Object.entries(intersectionRatioByTabId).reduce(
        (maxTab, [tab, ratio]) =>
          ratio > (intersectionRatioByTabId[maxTab] || 0)
            ? (tab as TaskDetailsTab)
            : maxTab,
        Object.keys(intersectionRatioByTabId)[0] as TaskDetailsTab
      ),
    [intersectionRatioByTabId]
  );

  const sectionRefs = {
    taskInfo: taskInfoSectionRef,
    subtasks: subtasksSectionRef,
    orderDetails: orderDetailsSectionRef,
    attachments: attachmentsSectionRef,
    history: historySectionRef,
  };

  return {
    sectionRefs,
    activeTab,
  };
};
