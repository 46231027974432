import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";

export const INITIAL_FILTERS_STATE: InternalTaskFilters = {
  boards: [],
  labels: [],
  priorities: [],
  statuses: [],
  assignees: [],
  customers: [],
  customFields: {},
  isOverdue: null,
  isRecurring: null,
};
