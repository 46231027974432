import { OrderResponseInvoiceItem } from "@jugl-web/rest-api";
import React, { useMemo } from "react";
import { priceDisplay } from "@jugl-web/utils";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export const MobileInvoiceItem: React.FC<{
  item: OrderResponseInvoiceItem;
  currency?: string;
  onUpdateItemQtyClick?: (isOpen: boolean) => void;
  onUpdateItemPriceClick?: (isOpen: boolean) => void;
}> = ({ item, currency, onUpdateItemPriceClick, onUpdateItemQtyClick }) => {
  const image = useMemo(
    () => item.img?.find((el) => el.order === 1)?.path || item.img?.[0]?.path,
    [item.img]
  );

  const undiscountedPriceInCents = useMemo(() => {
    if (item.discount?.discount) {
      const result =
        ((item.price * 100) / (100 - item.discount.discount)) * 100;
      return Number.isNaN(result) ? 0 : result;
    }
    return item.price * 100;
  }, [item.discount?.discount, item.price]);

  return (
    <>
      <div className="relative flex flex-col gap-3 px-4 py-5">
        <div className="flex items-start gap-3">
          {image ? (
            <div className="h-[72px] w-[72px] shrink-0 overflow-hidden rounded-[10px]">
              <img
                src={image}
                alt={item.name}
                className="h-full w-full object-cover"
              />
            </div>
          ) : (
            <div className="border-grey-400 flex h-[72px] w-[72px] shrink-0 items-center justify-center rounded-[10px] border border-solid bg-[#F2F2F4]">
              <ImageIcon />
            </div>
          )}
          <div className="flex flex-col gap-0.5 overflow-hidden">
            <span className="text-dark font-secondary truncate text-sm font-medium leading-[140%] tracking-[0.14px]">
              {item.name}
            </span>
            <span className="text-grey-800 font-secondary line-clamp-3 text-sm leading-[140%]">
              {item.desc}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div
            className="border-dark-100 flex h-8 w-[80px] items-center justify-center rounded-lg border-[1px] border-solid bg-white"
            onClick={() => {
              onUpdateItemQtyClick?.(true);
            }}
          >
            <span className="text-dark font-secondary text-sm">{item.qty}</span>
          </div>
          <span
            className="text-dark font-secondary flex items-center text-sm font-medium leading-[140%] tracking-[0.14px]"
            onClick={() => {
              onUpdateItemPriceClick?.(true);
            }}
          >
            {currency && item.discount?.discount && (
              <span className="text-gradients-danger pr-1 text-sm font-medium">
                {priceDisplay((item.total / item.qty) * 100, currency)}
                <div className="font-secondary text-right text-xs font-medium text-[#828282] line-through">
                  {priceDisplay(undiscountedPriceInCents, currency)}
                </div>
              </span>
            )}
            {currency &&
              !item.discount?.discount &&
              priceDisplay(item.price * 100 || 0, currency)}
          </span>
        </div>
        {item.discount?.discount && (
          <span className="bg-gradients-danger absolute top-0 right-0 ml-2 rounded-bl-lg px-2 py-1 text-center text-sm text-white">
            {` -${item.discount.discount}%`}
          </span>
        )}
      </div>
    </>
  );
};
