import { useRestApiProvider } from "@jugl-web/rest-api";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { WebTaskPageHeader } from "@web-guest-src/modules/tasks/pages/GuestTaskPage/components/WebTaskPageHeader";
import { FC } from "react";
import { useMatch } from "react-router-dom";
import { OrderSubmitForm } from "@jugl-web/domain-resources/orders/components/OrderSubmitForm";

export const GuestOrderFormPage: FC = () => {
  const match = useMatch("/order/:entityId/:formId");
  if (!match?.params.entityId || !match?.params.formId) {
    throw new Error("Invalid route");
  }
  const { ordersApi } = useRestApiProvider();
  const { entityId, formId } = match.params;
  const { data: initData } = ordersApi.useGuestInitQuery({ entityId });

  return (
    <MultiSectionLayout
      header={<WebTaskPageHeader title={initData?.entity?.name || ""} />}
      className="bg-dark-100 h-[100vh]"
    >
      <OrderSubmitForm
        captchaMode="grc"
        entityId={entityId}
        formId={formId}
        isGuestApp
      />
    </MultiSectionLayout>
  );
};
