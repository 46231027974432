import format from "date-fns/format";
import isThisYear from "date-fns/isThisYear";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import { useCallback } from "react";
import { useLanguage } from "../i18n/EnhancedIntlProvider";
import { useTranslations } from "./useTranslations";

type DateLabelFormattingVariant = "regular" | "concise";
type DateTimeLabelFormattingVariant = "regular";

interface HumanizesPastDateOptions {
  customJustNowMessage?: string;
}

export const useFormattedDate = () => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const localeAwareFormat = useCallback(
    (date: Date | number, formatString: string) =>
      format(date, formatString, {
        locale: dateLocale,
        weekStartsOn: 1,
      }),
    [dateLocale]
  );

  /**
   * Formats a date label based on the given variant:
   * - `regular` => `December 25, 2023`
   * - `concise` => `Dec 25, 2023`
   */
  const formatDateLabel = useCallback(
    (date: Date | number, variant: DateLabelFormattingVariant = "regular") => {
      if (isToday(date)) {
        return t({ id: "common.today", defaultMessage: "Today" });
      }

      if (isTomorrow(date)) {
        return t({ id: "common.tomorrow", defaultMessage: "Tomorrow" });
      }

      const formattedYear = isThisYear(date) ? "" : ", uuuu";

      if (variant === "concise") {
        return localeAwareFormat(date, "MMM d".concat(formattedYear));
      }

      return localeAwareFormat(date, "MMMM d".concat(formattedYear));
    },
    [localeAwareFormat, t]
  );

  /**
   * Formats a date time label based on the given variant:
   * - `regular` => `19 Jul 2024, 12:00 PM`
   */
  const formatDateTimeLabel = useCallback(
    (
      date: Date | number,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      variant: DateTimeLabelFormattingVariant = "regular"
    ) => {
      const dateLabel = (() => {
        if (isToday(date)) {
          return t({ id: "common.today", defaultMessage: "Today" });
        }

        if (isTomorrow(date)) {
          return t({ id: "common.tomorrow", defaultMessage: "Tomorrow" });
        }

        return localeAwareFormat(
          date,
          isThisYear(date) ? "d MMMM" : "d MMM uuuu"
        );
      })();

      const timeLabel = localeAwareFormat(date, "hh:mm a");

      return `${dateLabel}, ${timeLabel}`;
    },
    [localeAwareFormat, t]
  );

  /**
   * Humanizes a past date based on the given date.
   * (e.g. just now, 5 minutes ago, 1 hour ago, etc.)
   */
  const humanizePastDate = useCallback(
    (date: Date, options?: HumanizesPastDateOptions) => {
      const diff = Date.now() - date.getTime();

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(diff / (1000 * 60 * 60));

      if (seconds < 60) {
        return (
          options?.customJustNowMessage ||
          t({
            id: "common.just-now",
            defaultMessage: "Just now",
          })
        );
      }

      if (minutes < 60) {
        return t(
          {
            id: "common.date-minutes-ago",
            defaultMessage:
              "{minutes} {minutes, plural, one {minute} other {minutes}} ago",
          },
          {
            minutes,
          }
        );
      }

      if (hours < 24) {
        return t(
          {
            id: "common.date-hours-ago",
            defaultMessage:
              "{hours} {hours, plural, one {hour} other {hours}} ago",
          },
          {
            hours,
          }
        );
      }

      return localeAwareFormat(date, "dd MMM yyyy, hh:mm a");
    },
    [localeAwareFormat, t]
  );

  return {
    localeAwareFormat,
    formatDateLabel,
    formatDateTimeLabel,
    humanizePastDate,
  };
};
